/*----------------------------------------*/
/*  07. Product CSS
/*----------------------------------------*/
/*-- Product --*/
.single-grid-product{
    transition: 0.6s;
    & .product-label{
        span{
            position: absolute;
            z-index: 2;
            color: #e33;
            text-align: center;
            display: inline-block;
            top: 20px;
            right: 20px;
            font-size: 15px;
            line-height: 30px;
            font-weight: 600;
            &.new{
                color: #e33;
            }
    
            &.sale{
                color: $heading-color;
                left: auto;
                right: 20px;
            }
        }
    }

    & .product-image{
        position: relative;
        margin-bottom: 20px;
        & > a{
            display: block;
            width: 100%;

            & img{
                width: 100%;
                transition: 0.6s;
                &:nth-child(1){
                    visibility: visible;
                    opacity: 1;
                }
                &:nth-child(2){
                    position: absolute;
                    top: 0;
                    left: 0;
                    visibility: hidden;
                    opacity: 0;

                }
            }
        }
        & .product-action {
            position: absolute;
            top: 50%;
            left: 15px;
            z-index: 6;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            > ul > li {
              list-style: none;
              display: block;
              margin-bottom: 5px;
              opacity: 0;
               a{
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 18px;
                background: $white;
                display: inline-block;
                text-align: center;
                border-radius: 100%;
                color: $heading-color;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
                text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
                -webkit-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                &:hover {
                  color: $white;
                  background: $theme-color;
                }
              }
            }
          }

        
    }
    & .product-content{
        text-align: center;
        & .title{
            margin-bottom: 0px;
            font-size: 18px;
            line-height: 31px;
            font-weight: 500;
            a{
                display: block;
                &:hover{
                    color: $theme-color;
                }
            }
        }
    
        & p{
            .main-price{
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: #929292;
                &.discounted{
                    text-decoration: line-through;
                }
    
            }
    
            .discounted-price{
                margin-right: 10px;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                margin-right: 10px;
                color: #e33;
            }
        }
    }

    &:hover{
        .product-image{
            & > a{
                img{
                    
                    &:nth-child(2){
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            & .product-action{
                & ul{
                    & li{
                        &:nth-of-type(1) {
                            -webkit-animation: 500ms ease-in-out 0s normal none 1 running zoomIn;
                            animation: 500ms ease-in-out 0s normal none 1 running zoomIn;
                          }
                          &:nth-of-type(2) {
                            -webkit-animation: 700ms ease-in-out 0s normal none 1 running zoomIn;
                            animation: 700ms ease-in-out 0s normal none 1 running zoomIn;
                          }
                          &:nth-of-type(3) {
                            -webkit-animation: 900ms ease-in-out 0s normal none 1 running zoomIn;
                            animation: 900ms ease-in-out 0s normal none 1 running zoomIn;
                          }
                          &:nth-of-type(4) {
                            -webkit-animation: 1100ms ease-in-out 0s normal none 1 running zoomIn;
                            animation: 1100ms ease-in-out 0s normal none 1 running zoomIn;
                          }
                          opacity: 1;
                    }
                }
            }
        }
    }

    &.list-mode{
        display: flex !important;
        align-items: center !important;
        margin-bottom: 20px;

        & .list-mode-image{
            flex-basis: 100px;
        }

        & .list-mode-content{
            flex-basis: calc(100% - 100px);
            padding-left: 20px;
        }
    }

}
/* List Product CSS */
.product-list-item {
  & .single-grid-product{
      & .product-image{
          margin-bottom: 0;
      }
  }
}
.product-content-shop-list{
  text-align: left;
  & h3{
      font-size: 18px;
      line-height: 31px;
      font-weight: 500;
      margin: 0px 0 10px 0;
      // Responsive
      @media #{$small-mobile}{
        margin-top: 10px;
      }
      @media #{$extra-small-mobile}{
        margin-top: 10px;
      }
  }
  & .product-category-rating{
      margin: 0 0 10px 0;
      .review{
          a{
              font-weight: 400;
              line-height: 12px;
              color: #929292;
              margin-left: 10px;
              &:hover{
                  color: #292929;
              }
          }
      }

      .rating{
          margin-top: -3px;
          i{
              font-size: 15px;

              &.active{
                  color: #F5C60D;
              }
          }
      }
  }
  & .product-price{
      margin: 0 0 20px 0;
      // Responsive
      @media #{$large-mobile}{
          margin: 0 0 15px 0;
      }
      .main-price{
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #929292;
          &.discounted{
              text-decoration: line-through;
          }

      }

      .discounted-price{
          margin-right: 10px;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          margin-right: 10px;
          color: #e33;
      }
  }
  & .product-desc{
      border-top: 1px solid #ebebeb;
      padding-top: 20px;
      // Responsive
      @media #{$large-mobile}{
          padding-top: 15px;
      }
  }
  
  
}

/*-- Product Countdown CSS --*/
.product-details-content .product-countdown-two {
    margin-bottom: 25px;
    .single-count {
      padding: 15px 25px;
      // Responsive
      @media #{$large-mobile}{
        padding: 15px;
      }
      @media #{$tablet-device}{
        padding: 15px;
      }
      .single-countdown-times {
        font-size: 18px;
        // Responsive
        @media #{$tablet-device}{
            font-size: 14px;
        }
        @media #{$large-mobile}{
            font-size: 14px;
        }
      }
      .single-countdown-content {
        font-size: 10px;
      }
    }
  }
.product-countdown-two {
    

    .single-count {
        background-color: #292929;
        color: #ffffff;
        display: inline-block;
        text-transform: uppercase;
        padding: 15px; 
        font-weight: 400;
        border-right: 1px solid #ffffff; 
        text-align: center;
        // Responsive
        @media #{$large-mobile}{
          padding: 10px;
        }
        .single-countdown-times {
            display: block;
            font-size: 14px;

        }

        .single-countdown-content{
            font-size: 10px;
        }
    }
}
/* Shop Toolbar CSS */
.shop-area {
	position: relative;
	z-index: 9;
	background: $white;
  padding: 30px 0 0 0px;
    // Responsive
    @media #{$large-mobile}{
      padding: 15px 0 0 0px;
    }
}
.shop-topbar-wrapper{
    position: relative;
    padding: 10px 0;
    margin: 0 0 40px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    // Responsive
    @media #{$small-mobile}{
        flex-wrap: wrap;
    }
}
.grid-list-option{
    // Responsive
    @media #{$small-mobile}{
        flex: 0 0 100%;
        width: 100%;
    }
    & ul{
        // Responsive
        @media #{$large-mobile}{
            justify-content: center;
            margin-bottom: 10px;
        }
        & li{
            margin-right: 12px;
            &:last-child{
                margin-right: 0;
            }
            & a{
                font-size: 18px;
                display: block;
                text-align: center;
                color: $heading-color;
                &.active, &:hover{
                    color: $theme-color;
                }
            }
        }
    }
    & p{
        margin-left: 50px;
        // Responsive
        @media #{$large-mobile}{
            margin-left: 8px;
        }
    }
	
}
.toolbar-short-area{
    // Responsive
    @media #{$small-mobile}{
        flex: 0 0 100%;
        width: 100%;
    }
}
.toolbar-shorter {
	display: flex;
	align-items: center;
    margin-right: 20px;
    // Responsive
    @media #{$large-mobile}{
        justify-content: center;
        margin-right: 0;
    }
    @media #{$small-mobile}{
        justify-content: flex-start;
    }
    &:last-child{
        margin-right: 0;
    }
    & label {
        display: block;
        margin-bottom: 0;
        margin-right: 15px;
        font-size: 14px;
        color: $heading-color;
        text-transform: capitalize;
        // Responsive
        @media #{$large-mobile}{
            margin-right: 10px;
            width: 65px;
        }
    }
}
.nice-select.wide{
    width: 200px;
    // Responsive
    @media #{$large-mobile}{
        width: 165px;
    }
}
.nice-select.small{
    height: 42px;
    line-height: 40px;
    font-size: 14px;
    margin-right: 10px;
}
/*-- Product Details --*/
.product-details-images .lg-image {
  position: relative;
    > a {
    width: 100%;
    }
    img {
    width: 100%;
    }
}
.product-details-thumbs , .product-details-thumbs-2 {
    margin-top: 15px;
    overflow: hidden;
    & .slick-list{
    margin: 0 -5px;
    & .slick-slide{
        padding: 0 5px;
        & > img{
            border: 1px solid transparent;
        }
    }
    }
    & .slick-active.slick-current{
        & > img{
        border-color: $theme-color;
        }
    }
}
  
  .product-details-thumbs .sm-image {
    cursor: pointer;
  }
  .product-large-thumb {
    position: relative;
    overflow: hidden;
  }
  
  a.popup-img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px !important;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #373737;
    color: #fff;
    border-radius: 4px;
    z-index: 9;
  }
  
  .product-large-thumb a.popup-img:hover {
    background-color: $theme-color;
    color: #fff;
  }
  .product-details-content {
    position: relative;
    // Responsive
    @media #{$large-mobile}{
        margin-top: 30px;
    }
  }
  
  .product-nav {
    position: absolute;
    top: 0;
    right: 0;
    > a {
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 1px solid #d1d1d1;
      line-height: 28px;
      text-align: center;
      border-radius: 3px;
      color: #d1d1d1;
      position: relative;
      margin-right: 5px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: $theme-color;
        border-color: $theme-color;
        color: #fff;
      }
    }
  }
  
  .product-details-content {
      &.text-center{
          h2{
              max-width: 100%;
              // Responsive
              @media #{$large-mobile}{
                max-width: 245px;
                font-size: 18px;
                margin: auto;
                margin-bottom: 10px;
              }
              @media #{$small-mobile}{
                max-width: 195px;
                font-size: 17px;
                margin: 0;
                margin-bottom: 10px;
                text-align: left;
              }
          }
      }
    & h2{
        color: $heading-color;
        font-size: 23px;
        font-weight: 500;
        margin-bottom: 10px;
        max-width: 400px;
        // Responsive
        @media #{$desktop-device}{
            max-width: 370px;
        }
        @media #{$tablet-device}{
            font-size: 17px;
            max-width: 240px;
        }
        @media #{$large-mobile}{
            font-size: 20px;
        }
        @media #{$small-mobile}{
            font-size: 18px;
            max-width: 300px;
        }
        @media #{$extra-small-mobile}{
            font-size: 18px;
            max-width: 175px;
        }
    }
  }
  
  .single-product-reviews {
      & i.active{
        color: #F5C60D;
      }
    font-size: 15px;
    margin-bottom: 20px;
  }
  
  .review-link {
    font-size: 15px;
    color: $heading-color;
    margin-left: 10px;
    line-height: 30px;
    display: inline-block;
    &:hover {
      color: $theme-color;
    }
  }
  
  .single-product-price {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
    .price.new-price {
      color: $theme-color;
      font-weight: 500;
    }
    .regular-price {
      text-decoration: line-through;
    }
  }
  
  .product-description {
      & p{
            line-height: 25px;
            color: $body-color;
            margin-bottom: 30px;
        }
    
  }
  .single-product-quantity {
    margin-bottom: 35px;
    .product-quantity {
      background: #fff;
      border: 1px solid #ededed;
      float: left;
      margin-right: 15px;
      padding: 0 10px;
      border-radius: 0;
      input {
        background: none;
        border: none;
        color: $heading-color;
        font-size: 20px;
        font-weight: 400;
        height: 55px;
        line-height: 53px;
        padding: 0;
        width: 60px;
        // Responsive
        @media #{$large-mobile}{
            height: 45px;
            width: 40px;
        }
      }
    }
    .btn{
        text-transform: uppercase;
        // Responsive
        @media #{$large-mobile}{
            padding: 8px 15px;
        }
        & i{
            font-size: 20px;
            margin-right: 10px;
        }
      }
  }
  
  
  .wishlist-compare-btn {
    margin-bottom: 35px;
    // Responsive
    @media #{$large-mobile}{
        margin-bottom: 20px;
    }
    @media #{$small-mobile}{
        text-align: left;
    }
    @media #{$extra-small-mobile}{
        text-align: center;
    }
    a {
      background: none;
      border: 1px solid #ededed;
      color: $heading-color;
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      height: 45px;
      line-height: 43px;
      text-align: center;
      width: 170px;
      border-radius: 0;
      -webkit-transition: .3s ease;
      transition: .3s ease;
      // Responsive
      @media #{$tablet-device}{
            width: 155px;
        }
      &.wishlist-btn {
        margin-right: 20px;
        // Responsive
        @media #{$tablet-device}{
            margin-right: 0;
        }
        @media #{$large-mobile}{
            margin-right: 0;
            margin-bottom: 15px;
        }
      }
      &.add-compare {
        border-radius: 5px;
      }
      &.wishlist-btn:before {
        content: '\f08a';
        display: inline-block;
        font-family: FontAwesome;
        font-size: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
      &:hover {
        background: $theme-color;
        border-color: $theme-color;
        color: #fff;
        &:before {
          -webkit-animation: crFlipX 2s ease-in-out infinite both;
          animation: crFlipX 2s ease-in-out infinite both;
        }
      }
    }
  }
  
  .product-meta {
    border-bottom: 1px solid #ededed;
    border-top: 1px solid #ededed;
    padding: 15px 0;
    width: 100%;
    .posted-in {
      color: $heading-color;
      font-size: 14px;
      font-weight: 700;
      margin-right: 10px;
      text-transform: uppercase;
      > a {
        color: $heading-color;
        font-weight: 400;
        text-transform: none;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }
  
  .single-product-sharing {
    margin-bottom: 0;
    padding: 15px 0;
    width: 100%;
    > h3 {
      color: $heading-color;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin-right: 15px;
      text-transform: uppercase;
    }
    ul {
      display: inline-block;
      margin-bottom: 10px;
      > li {
        display: inline-block;
        list-style: none;
        margin-bottom: 0;
        margin-right: 15px;
        position: relative;
      }
    }
  }

  .single-product-sharing {
    & ul{
        & li{
            & a{
                color: #555555;
                display: block;
                font-size: 16px;
                text-align: center;
                text-transform: uppercase;
                &:hover {
                color: $theme-color;
                }
            }
        }
    }
  }
/*Single Product variable css*/

.product-variants {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    > .product-variants-item {
      margin: 20px 0;
      display: block;
      margin-top: 0;
      &:last-child {
        margin-left: 30px;
      }
    }
  }
  
  .product-variants-item span.control-label {
    margin-bottom: 6px;
    margin-bottom: 5px;
    display: block;
    width: 100%;
    text-transform: capitalize;
  }
  
  .product-variants{
      & .product-variants-item{
          & select{
            border: 1px solid #ebebeb;
            height: 40px;
            width: 60px;
            background: #fff;
            border: 1px solid #ebebeb;
            height: 40px;
            width: 60px;
            background: #fff;
          }
      }
  }
  
  .procuct-color {
    & > li{
        display: inline-block;
        list-style: none;
        margin-left: 5px;
        &:nth-child(2){
            & > a{
                & > span.color{
                    background: #00EEB3;
                }
            }
        }
        & > a{
            & > span.color{
                background: #ffa07a;
                height: 20px;
                width: 20px;
                display: inline-block;
                margin-right: 10px;
                -webkit-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
            }
        }
        &.active, &:hover{
            & > a{
                & > span.color{
                    outline: 1px solid #D6B98C;
                    outline-offset: 1px; 
                }
            }
        }
    }
  }
/*single product group css*/
.group-cart-table {
    // Responsive
    @media #{$tablet-device}{
        margin-bottom: 20px;
    }
    @media #{$small-mobile}{
        margin-bottom: 20px;
    }
    .table{
        margin-bottom: 35px;
        // Responsive
        @media #{$tablet-device}{
            margin-bottom: 15px;
        }
        @media #{$small-mobile}{
            margin-bottom: 15px;
        }
        & td{
            text-align: center;
            vertical-align: middle;
            // Responsive
            @media #{$tablet-device}{
                min-width: 150px;
            }
            @media #{$small-mobile}{
                min-width: 180px;
            }
            
        }
    }
    .quantity-field {
      label {
        margin-right: 5px;
      }
      input {
        width: 50px;
        text-align: center;
        border: 1px solid #ddd;
        height: 45px;
      }
    }
  }
/*Single Product Tab Style 2 CSS*/

.single-product-area {
    & .slider-thumbs-2{
        padding: 40px 0px;
    }
  }
  
  .product-details-images-2 {
    float: right;
    width: calc(100% - 110px);
    padding-left: 10px;
    // Responsive
    @media #{$tablet-device}{
        width: calc(100% - 80px);
    }
    @media #{$large-mobile}{
        width: calc(100% - 80px);
    }
  }
  
  .product-details-thumbs-2 {
    float: left;
    width: 110px;
    // Responsive
    @media #{$tablet-device}{
        width: 80px;
    }
    @media #{$large-mobile}{
        width: 80px;
    }
  }
  
  /*Single Product Tab Style 3 CSS*/
  
  .product-details-images-2.tabstyle-3 {
    float: left;
    width: calc(100% - 110px);
    padding-right: 10px;
    // Responsive
    @media #{$tablet-device}{
        width: calc(100% - 80px);
    }
    @media #{$large-mobile}{
        width: calc(100% - 80px);
    }
  }
  
  .product-details-thumbs-2.tabstyle-3 {
    float: right;
    width: 110px;
    // Responsive
    @media #{$tablet-device}{
        width: 80px;
    }
    @media #{$large-mobile}{
        width: 80px;
    }
  }
/*Single Product gallery Style CSS*/
.lg-image.col-50 {
    float: left;
    width: 50%;
    padding: 5px;
    position: relative;
    a.popup-img {
      bottom: 5px;
      right: 5px;
    }
  }
/*Single Product sticky Style CSS*/
.lg-image.sidebar-sticky {
    position: relative;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
/*Single Product slider box Style CSS*/

.slider-box {
    margin-bottom: 50px;
    // Responsive
    @media #{$large-mobile}{
        margin-bottom: 0;
    }
    & .slick-list{
        margin: 0 -10px;
        & .slick-slide{
            padding: 0 10px;
        }
    }
  }
  
  .slider-box-content {
    .product-description p {
      max-width: 80%;
      margin: auto;
      margin-bottom: 15px;
    }
    .product-quantity {
      float: none;
      display: inline-block;
    }
    .add-to-link {
      display: inline-block;
    }
  }
/*-- Product Details Tab List --*/
.dec-and-review-menu {
    justify-content: center;
    padding: 15px 0;
    background-color: #f3f1ec;
    > li {
      list-style: none;
      position: relative;
      z-index: 9;
      display: inline-block;
      margin-right: 50px;
      // Responsive
      @media #{$small-mobile}{
          margin-bottom: 10px;
          margin-right: 0;
          text-align: center;
          display: block;
          width: 100%;
      }
      &:last-child{
          margin-right: 0;
      }
      > a {
        display: block;
        font-size: 20px;
        text-transform: capitalize;
        font-weight: 500;
        &.active, &:hover {
          color: $heading-color;
        }
      }
    }
  }

/*-- Product Details Tab Content --*/
.product-review-tab{
    padding-top: 100px;
    padding-bottom: 100px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    // Responsive
    @media #{$laptop-device}{
      padding-top: 80px;
      padding-bottom: 80px;
    }
    @media #{$desktop-device}{
      padding-top: 70px;
      padding-bottom: 70px;
    }
    @media #{$tablet-device}{
      padding-top: 60px;
      padding-bottom: 60px;
    }
    @media #{$large-mobile}{
      padding-top: 50px;
      padding-bottom: 50px;
    }
}
  .product-review-content-tab {
    padding-top: 40px;
    font-size: 15px;
    line-height: 26px;
    width: 100%;
    // Responsive
    @media #{$large-mobile}{
      padding-top: 20px;
    }
  }
  
  .single-product-description {
    p {
      line-height: 22px;
      color: $body-color;
    }
  }
  
  .review-page-comment {
    h2 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
      text-transform: capitalize;
    }
    > ul {
      margin: 0 0 25px;
      > li {
        margin: 0 0 20px;
        position: relative;
        list-style: none;
      }
    }
  }
  
  .product-comment img {
    width: 60px;
    float: left;
  }
  
  .product-comment-content {
    border: 1px solid #ededed;
    border-radius: 3px;
    margin-left: 80px;
    position: relative;
    padding: 15px 15px 0;
    &:before {
      background: #fff;
      border-bottom: 1px solid #ededed;
      border-left: 1px solid #ededed;
      content: '';
      display: block;
      height: 10px;
      left: -6px;
      position: absolute;
      top: 10px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 10px;
    }
  }
  
  .meta > {
    strong {
      font-size: 14px;
      text-transform: uppercase;
      line-height: 31px;
    }
    span {
      font-size: 15px;
      font-weight: 400;
      line-height: 31px;
    }
  }
  
  .description {
    & p{
        font-size: 15px;
        color: $body-color;
        line-height: 28px;
    }
  }
  
  .comment-notes {
    margin: 0;
  }
  
  .rating i.fa:not(:last-child) {
    color: #F9BA48;
  }
  
  .comment-form-comment {
    margin-bottom: 10px;
    textarea {
      border: 1px solid #ededed;
      border-radius: 0;
      height: 125px;
      padding: 10px;
      width: 100%;
      background: #f5f5f5;
    }
  }
  
  .review-comment-form-author, .review-comment-form-email {
    margin-bottom: 10px;
  }
  
  .review-comment-form-author input, .review-comment-form-email input {
    background: #f5f5f5;
    border: none;
    border-radius: 0;
    height: 38px;
    line-height: 38px;
    max-width: 100%;
    width: 300px;
    display: block;
    padding: 0 0 0 10px;
    vertical-align: middle;
  }
  
  .review-comment-form-author input:focus, .review-comment-form-email input:focus, .comment-form-comment textarea:focus {
    outline: auto;
    outline-color: $theme-color;
  }
  
  .form-button {
    border: none !important;
    height: 42px !important;
    line-height: 40px !important;
    background: $theme-color;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    height: 38px;
    letter-spacing: .1em;
    line-height: 38px;
    overflow: hidden;
    padding: 0 30px;
    text-shadow: none;
    text-transform: uppercase;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    vertical-align: middle;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: #222;
      color: #fff;
    }
  }
