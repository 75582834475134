/*---------------------------------------
    15. Blog CSS
-----------------------------------------*/
/*-- Blog --*/
.blog {
    & .blog-inner {
        & .blog-media {
            margin-bottom: 15px;
            & .image {
                display: block;
                & img {
                    width: 100%;
                }
            }
        }
        & .blog-audio{
            & iframe{
                width: 100%;
                height: 242px;
                border: 0;
                // Responsive
                @media #{$desktop-device}{
                    height: 190px;
                }
            }
        }
        & .blog-video{
            & iframe{
                width: 100%;
                height: 242px;
                border: 0;
                // Responsive
                @media #{$desktop-device}{
                    height: 190px;
                }
            }
        }
        & .blog-gallery{
            & a{
                display: block;
                & img{
                    width: 100%;
                }
            }
        }
        & .content {
            & .meta {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 5px;
                & li {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 30px;
                    text-transform: capitalize;
                    margin-bottom: 5px;
                    // Responsive
                    @media #{$desktop-device}{
                        font-size: 14px;
                    }
                    @media #{$large-mobile}{
                        font-size: 13px;
                    }
                    &::after {
                        content: "-";
                        margin: 0 10px;
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                    & a {}
                }
            }
            & .title {
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
                margin-bottom: 10px;
                // Responsive
                @media #{$desktop-device}{
                    font-size: 16px;
                }
                @media #{$large-mobile}{
                    font-size: 16px;
                }
                & a {}
            }
            & .desc {
                margin-top: 20px;
                & p {}
            }
            & .read-more {
                font-size: 16px;
                line-height: 30px;
                text-decoration: underline;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}

/*-- Blog Details --*/
.blog-details {
  & .blog-inner {
      & .blog-media {
          margin-bottom: 30px;
          & .image {
              display: block;
              & img {
                  width: 100%;
              }
          }
      }
      & .blog-audio{
          & iframe{
              width: 100%;
              border: 0;
          }
      }
      & .blog-video{
          & iframe{
              width: 100%;
              border: 0;
              height: 565px;
              // Responsive
              @media #{$laptop-device}{
                  height: 465px;
              }
              @media #{$desktop-device}{
                  height: 400px;
              }
              @media #{$tablet-device}{
                  height: 400px;
              }
              @media #{$large-mobile}{
                  height: 300px;
              }
          }
      }
      & .content {
          & .meta {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 10px;
              & li {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18px;
                  text-transform: capitalize;
                  margin-bottom: 5px;
                  //Responsive
                  @media #{$large-mobile}{
                      font-size: 12px;
                  }
                  &::after {
                      content: "-";
                      margin: 0 10px;
                  }
                  &:last-child {
                      &::after {
                          display: none;
                      }
                  }
                  & a {}
              }
          }
          & .title {
              font-size: 28px;
              font-weight: 600;
              margin-bottom: 0;
              // Responsive
              @media #{$large-mobile}{
                  font-size: 24px;
              }
              @media #{$extra-small-mobile}{
                  font-size: 20px;
              }
              & a {}
          }
          & .desc {
              margin-top: 20px;
              & p {}
          }
          .blog-post-list{
            & li{
              display: block;
              list-style: none;
              line-height: 30px;
              margin-bottom: 15px;
              position: relative;
              padding-left: 20px;
              &::before{
                position: absolute;
                content: "\f101";
                font-family: FontAwesome;
                top: 0;
                left: 0;
                z-index: 1;
              }
            }
            
          }
          & .tags {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              & li {
                  display: flex;
                  flex-wrap: wrap;
                  line-height: 24px;
                  margin-right: 15px;
                  &::after {
                      content: ",";
                      margin-left: 3px;
                  }
                  &:first-child, &:last-child {
                      &::after {
                          display: none;
                      }
                  }
                  & i {
                      font-size: 18px;
                      line-height: 24px;
                  }
                  & a {
                      display: block;
                  }
              }
          }
      }
  }
}


.comments-wrapper{
  & h3{
    font-weight: 600;
  }
}
.commnent-list-wrap{
  .comment{
      list-style-type: none;
      margin-top: 48px;
      padding-top: 37px;
      padding-left: 6px;
      border-top: 1px solid #eee;
      // Responsive
      @media #{$tablet-device}{
        margin-top: 25px;
      }
      .thumb{
          float: left;
          @media #{$large-mobile} {
              float: none;
          }
          img{
              border-radius: 100%;
              width: 100px;
              height: 100px;
          }
      }
      .content{
          position: relative;
          overflow: hidden;
          margin-left: 115px;
          @media #{$large-mobile} {
              margin-left: 0;
              margin-top: 30px;
          }
          & .info{
            & h6{
              font-size: 15px;
              font-weight: 500;
            }
          }
          .reply-btn{
              a{
                  color: $white;
                  -webkit-transition: 0.4s;
                  -o-transition: 0.4s;
                  transition: 0.4s;
                  display: inline-block;
                  font-size: 12px;
                  border: 1px solid $theme-color;
                  background: $theme-color;
                  padding: 3px 10px;
                  border-radius: 10px 0;
                  transition: 0.3s;
                  &:hover{
                      color: $theme-color;
                      border: 1px solid $theme-color;
                      background: transparent;
                  }
              }
          }
          .comment-footer{
              span{
                  font-size: 14px;
                  font-weight: 400;
                  display: inline-block;
                  
              }
          }
      }

      &.comment-reply{
          padding-left: 61px;
          @media #{$large-mobile} {
              padding-left: 30px;
          }
          @media #{$extra-small-mobile} {
              padding-left: 0;
          }
      }
  }
}