/*---------------------------------------
    22. 404 CSS
-----------------------------------------*/

.error-text > {
    h1 {
      font-size: 130px;
      color: $heading-color;
      font-weight: 900;
      letter-spacing: 10px;
      line-height: 130px;
      margin-bottom: 10px;
      // Responsive
      @media #{$desktop-device}{
        font-size: 100px;
        line-height: 100px;
      }
      @media #{$tablet-device}{
        font-size: 90px;
        line-height: 90px;
      }
      @media #{$large-mobile}{
        font-size: 120px;
        line-height: 120px;
      }
      @media #{$small-mobile}{
        font-size: 120px;
        line-height: 120px;
      }
    }
    h2 {
      font-size: 28px;
      line-height: 40px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 5px;
      color: $heading-color;
      // Responsive
      @media #{$tablet-device}{
        font-size: 22px;
        line-height: 22px;
      }
      @media #{$large-mobile}{
        font-size: 28px;
        line-height: 28px;
      }
      @media #{$small-mobile}{
        font-size: 18px;
        line-height: 18px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      margin: 20px auto 30px;
      color: $body-color;
      max-width: 700px;
      // Responsive
      @media #{$tablet-device}{
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      @media #{$large-mobile}{
        font-size: 18px;
        line-height: 24px;
        max-width: 450px;
      }
      @media #{$small-mobile}{
        font-size: 15px;
        line-height: 24px;
        max-width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .error-button a {
    background: $theme-color none repeat scroll 0 0;
    border-radius: 35px;
    color: #ffffff;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    line-height: 40px;
    margin-top: 10px;
    padding: 0 30px;
    text-transform: uppercase;
    &:hover {
      background: #333;
    }
  }