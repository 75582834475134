/*---------------------------------------
    19. Contact CSS
-----------------------------------------*/

/*-- Map --*/
.contact-map {
    height: 400px;
    width: 100%;
}

/*-- Contact Information --*/
.contact-information {
    & h3 {
        margin-bottom: 30px;
        font-weight: 600;
    }
    & ul {
        & li {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            & .icon {
                width: 50px;
                & i {
                    font-size: 30px;
                }
            }
            & .text {
                max-width: calc(100% - 50px);
                & span, & a {
                    display: block;
                    line-height: 24px;
                    max-width: 230px;
                }
            }
        }
    }
}

/*-- Contact Form --*/
.bl{
    border-left: 1px solid #dddd;
}
.name-fild-padding{
    padding-right: 30px;
}
.contact-form-wrap {
    & h3 {
        font-size: 36px;
        margin-bottom: 30px;
        font-weight: 600;
        position: relative;
        // Responsive
        @media #{$large-mobile}{
            font-size: 28px;
        }
        &::before{
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 70px;
            height: 2px;
            background: #151515;
        }
    }
    & .fild-name{
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
    }
    & input {
        width: 100%;
        height: 50px;
        border: 0;
        border-bottom: 2px solid $body-color;
        padding: 5px 0px;
        color: $body-color;
        border-radius: 0px;
        height: 45px;
        &:focus{
            border-color: #777777;
            color: #111111;
        }
    }
    & textarea {
        width: 100%;
        height: 200px;
        border: 0;
        border-bottom: 2px solid $body-color;
        padding: 10px 00px;
        color: $body-color;
        font-size: 16px;
        font-weight: 500;
        resize: none;
        border-radius: 0px;
        &:focus{
            border-color: #777777;
            color: #111111;
        }
    }
    & button{
        margin-top: -5px;
        display: block;
        border-radius: 0px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }
    & .check-box {
        float: left;
        margin-right: 70px;
        &:last-child {
            margin-right: 0;
        }
        & input[type="checkbox"] {
            display: none;
            & + label {
                position: relative;
                padding-left: 30px;
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: $heading-color;
                margin: 0;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    display: block;
                    border: 2px solid #999999;
                    content: "";
                    transition: all 0.3s ease 0s;
                }
                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                    content: "\f00c";
                    font-family: Fontawesome;
                    font-size: 12px;
                    line-height: 20px;
                    opacity: 0;
                    color: $heading-color;
                    width: 20px;
                    text-align: center;
                    transition: all 0.3s ease 0s;
                }
            }
            &:checked + label {
                &::before {
                    border: 2px solid $heading-color;
                }
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}
.form-messege.success {
	color: #1dbc51;
	font-weight: 700;
}
.form-messege.error{
    color: #ff1313;
}