/*----------------------------------------*/
/*  05. Banner CSS
/*----------------------------------------*/

/* Banner CSS */
.single-banner-item{
    position: relative;
    z-index: 2;
    & .banner-image{
        position: relative;
        overflow: hidden;
        & a{
            display: block;
            width: 100%;
            & img{
                width: 100%;
                transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
    & .banner-content {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        text-align: center;
        // Responsive
        @media #{$large-mobile}{
            bottom: 30px;
        }
        &.tr-right{
            bottom: auto;
            top: 40px;
            right: 90px;
            left: auto;
            // Responsive
            @media #{$large-mobile}{
                top: 30px;
                right: 26px;
            }
        }
        &.tl-left{
            left: 40px;
            right: auto;
            top: 40px;
            // Responsive
            @media #{$large-mobile}{
                left: 15px;
                top: 25px;
            }
        }
        &.color-white{
            & .title{
                color: $white;
            }
            & a{
                color: $white;
            }
        }
        & .title{
            font-size: 28px;
            line-height: 32px;
            font-weight: 700;
            // Responsive
            @media #{$large-mobile}{
                font-size: 24px;
                line-height: 28px;
            }
        }
        & a{
            font-size: 16px;
            line-height: 32px;
            text-decoration: underline;
            font-weight: 500;
        }
    }
    .discount-banner-content {
        position: absolute;
        top: 40px;
        left: 40px;
        // Responsive
        @media #{$large-mobile}{
            top: 20px;
            left: 20px;
        }
        & h3{
            font-size: 24px;
            line-height: 48px;
            font-weight: 600;
            margin-bottom: 0;
            // Responsive
            @media #{$large-mobile}{
                font-size: 22px;
                line-height: 38px;
            }
            & span{
                font-size: 55px;
                color: #ff0000;
                font-weight: 700;
                // Responsive
                @media #{$large-mobile}{
                    font-size: 50px;
                }
            }
        }
    }
    & .sp-banner-content {
        padding-left: 145px;
        // Responsive
        @media #{$desktop-device}{
            padding-left: 100px;
        }
        @media #{$tablet-device}{
            padding-left: 80px;
        }
        @media #{$large-mobile}{
            padding-left: 15px;
        }
        &.content-right{
            padding-left: 420px;
            padding-top: 60px;
            padding-bottom: 65px;
            // Responsive
            @media #{$desktop-device}{
                padding-top: 30px;
                padding-bottom: 35px;
                padding-left: 320px;
            }
            @media #{$tablet-device}{
                padding-top: 55px;
                padding-bottom: 60px;
                padding-left: 400px;
            }
            @media #{$large-mobile}{
                padding-top: 55px;
                padding-bottom: 60px;
                padding-left: 10px;
            }
        }
        & .normat-text{
            display: block;
            font-size: 18px;
            line-height: 40px;
            text-transform: uppercase;
            font-weight: 400;
            // Responsive
            @media #{$large-mobile}{
                font-size: 16px;
                line-height: 36px;
            }
        }
        & .title{
            font-size: 36px;
            line-height: 40px;
            font-weight: 700;
            margin-bottom: 5px;
            // Responsive
            @media #{$large-mobile}{
                font-size: 24px;
                line-height: 28px;
            }
        }
        & .countdown-area {
            display: flex;
            align-items: flex-end;
            margin-bottom: 35px;
            // Responsive
            @media #{$tablet-device}{
                margin-bottom: 15px;
            }
            @media #{$large-mobile}{
                margin-bottom: 10px;
            }
            .single-countdown{
                display: inline-block;
                font-size: 20px;
                line-height: 1;
                font-weight: 600;
                position: relative;
                // Responsive
                @media #{$large-mobile}{
                    font-size: 18px;
                    line-height: 1;
                }
                &.big-font{
                    font-size: 30px;
                    line-height: 1;
                    margin-right: 15px;
                    // Responsive
                    @media #{$large-mobile}{
                        font-size: 26px;
                        line-height: 26px;
                    }
                }
                &:nth-child(1){
                    &::before{
                        display: none;
                    }
                }
                &:nth-child(2){
                    &::before{
                        display: none;
                    }
                }
                &::before{
                    content: ':';
                    margin: 0 5px;
                }
            }
        }
        & a{
            font-size: 16px;
            line-height: 32px;
            text-decoration: underline;
            font-weight: 500;
        }
    }
    // Responsive
    @media #{$large-mobile}{
        &::before{
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: #fff;
            z-index: -1;
            opacity: 0.7;
        }
    }
    &:hover{
        & .banner-image{
            & a{
                & img{
                    transform: scale(1.1);
                }
            }
        }
    }
}


