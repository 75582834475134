/*---------------------------------------
    09. Cart CSS
-----------------------------------------*/

/*-- Cart Table --*/
.cart-table {
    & .table {
        border-bottom: 2px solid $heading-color;
        margin: 0;
        // Responsive
        @media #{$large-mobile}{
            border-top: 2px solid $heading-color;
        }
        // Head
        & thead {
            background-color: $heading-color;
            // Responsive
            @media #{$large-mobile}{
                display: none;
            }
            & tr {
                & th {
                    text-align: center;
                    border: none;
                    font-size: 16px;
                    font-weight: 600;
                    color: $white;
                    padding: 12px 20px;
                }
            }
        }
        // Body
        & tbody {
            & tr {
                & td {
                    text-align: center;
                    border: none;
                    padding: 25px 20px;
                    vertical-align: middle;
                    border-bottom: 1px solid #dddddd;
                    // Responsive
                    @media #{$large-mobile}{
                        display: block;
                        width: 100%;
                        max-width: none;
                        padding: 15px;
                        text-align: left;
                    }
                }
            }
        }
    }
}
.cart-table {
    & th.pro-thumbnail, td.pro-thumbnail {
        max-width: 120px;
        min-width: 100px;
        width: 120px;
        // Responsive
        @media #{$large-mobile}{
            & a {
                width: 120px;
            }
        }
    }
    & th.pro-title, td.pro-title {
        min-width: 200px;
    }
    & th.pro-price, td.pro-price {}
    & th.pro-quantity, td.pro-quantity {}
    & th.pro-subtotal, td.pro-subtotal {}
    & th.pro-remove, td.pro-remove {}
    & td.pro-thumbnail {
        & a {
            display: block;
            border: 1px solid #eeeeee;
            & img {
                width: 100%;
                background-color: #f6f7f8;
            }
        }
    }
    & td.pro-title {
        & a {
            font-size: 16px;
            font-weight: 600;
            color: $body-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
    & td.pro-price {
        & span {
            display: block;
            font-size: 15px;
            font-weight: 600;
            color: $body-color;
        }
    }
    & td.pro-quantity {
        & .pro-qty {
            display: inline-flex;
            text-align: center;
            border: 1px solid #eeeeee;
            & .qtybtn {
                height: 42px;
                padding: 0 10px;
                border: none;
                background-color: transparent;
            }
            & input {
                height: 42px;
                width: 50px;
                text-align: center;
                border-width: 0 1px;
                border-style: solid;
                border-color: #eeeeee;
                color: $body-color;
            }
        }
    }
    & td.pro-subtotal {
        & span {
            display: block;
            font-size: 15px;
            font-weight: 600;
            color: $body-color;
        }
    }
    & td.pro-addtocart {
        
    }
    & td.pro-stock{
        & span.in-stock{
            font-size: 14px;
            text-transform: capitalize;
            color: #30b878;
        }
        & span.out-stock{
            color: #EB3E32;
        }
    }
    & td.pro-remove {
        & a {
            display: block;
            font-weight: 600;
            color: $body-color;
            & i {
                font-size: 15px;
            }
            &:hover {
                color: #ff0000;
            }
            
            // Responsive
            @media #{$large-mobile}{
                width: 60px;
                text-align: center;
            }
        }
    }
}

/*-- Calculate Shipping --*/
.calculate-shipping {
    margin-bottom: 23px;
    & h4 {
        font-size: 20px;
        line-height: 23px;
        text-decoration: underline;
        text-transform: capitalize;
        font-weight: 600;
        margin-bottom: 30px;
    }
    & form {
        & .nice-select {
            width: 100%;
            border-radius: 0;
            height: 55px;
            border: 1px solid #999999;
            line-height: 40px;
            padding: 9px 20px;
            color: $body-color;
            background-color: transparent;
        }
        & input {
            width: 100%;
            height: 55px;
            border: 1px solid #999999;
            line-height: 24px;
            padding: 9px 20px;
            color: $body-color;
            color: $body-color;
            background-color: transparent;
            &[type="submit"] {
                font-weight: 700;
                color: $heading-color;
                background-color: $theme-color;
                border-color: $theme-color;
                width: 140px;
                &:hover {
                    background-color: $heading-color;
                    border-color: $heading-color;
                    color: $theme-color;
                }
            }
        }
    }
}

/*-- Discount Coupon --*/
.discount-coupon {
    & h4 {
        font-size: 20px;
        line-height: 23px;
        text-decoration: underline;
        text-transform: capitalize;
        font-weight: 600;
        margin-bottom: 30px;
    }
    & form {
        & input {
            width: 100%;
            height: 55px;
            border: 1px solid #999999;
            line-height: 24px;
            padding: 9px 20px;
            color: $body-color;
            background-color: transparent;
            &[type="submit"] {
                font-weight: 700;
                color: $heading-color;
                background-color: $theme-color;
                border-color: $theme-color;
                width: 140px;
                &:hover {
                    background-color: $heading-color;
                    border-color: $heading-color;
                    color: $theme-color;
                }
            }
        }
    }
}

/*-- Cart Summary --*/
.cart-summary {
    float: right;
    max-width: 410px;
    width: 100%;
    margin-left: auto;
    // Responsive
    @media #{$tablet-device}{
        margin-left: 0;
        max-width: 100%;
    }
    @media #{$large-mobile}{
        margin-left: 0;
        max-width: 100%;
    }
    & .cart-summary-wrap {
        background-color: #f5f5f5;
        padding: 45px 50px;
        margin-bottom: 20px;
        // Responsive
        @media #{$small-mobile}{
            padding: 25px 30px;
        }
        & h4 {
            font-size: 20px;
            line-height: 23px;
            text-decoration: underline;
            text-transform: capitalize;
            font-weight: 600;
            margin-bottom: 30px;
            color: $heading-color;
        }
        & p {
            font-weight: 600;
            line-height: 23px;
            color: $heading-color;
            & span {
                float: right;
            }
        }
        & h2 {
            border-top: 1px solid $heading-color;
            padding-top: 9px;
            font-size: 18px;
            line-height: 23px;
            font-weight: 600;
            color: $heading-color;
            margin: 0;
            & span {
                float: right;
            }
        }
    }
    & .cart-summary-button {
        overflow: hidden;
        width: 100%;
        
        // Responsive
        @media #{$tablet-device}{
            display: flex;
            justify-content: flex-start;
        }
        @media #{$large-mobile}{
            display: flex;
            justify-content: flex-start;
        }
        & button {
            margin-top: 10px;
            width: 180px;
            border-radius: 0;
            height: 44px;
            line-height: 24px;
            padding: 9px 20px;
            margin-left: 20px;
            float: right;
            &:last-child {
                margin-left: 0;
            }
            &.checkout-btn {
                color: $heading-color;
                background-color: $theme-color;
                border-color: $theme-color;
            }
            &:hover {
                background-color: $theme-color;
                border-color: $theme-color;
                color: $white;
            }
            // Responsive
            @media #{$tablet-device}{
                margin-left: 0;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
            }
            @media #{$large-mobile}{
                margin-left: 0;
                margin-right: 10px;
                font-size: 12px;
                &:last-child {
                    margin-right: 0;
                }
            }
            @media #{$small-mobile}{
                width: 130px;
            }
        }
    }
}