/*-----------------------------------------------------------------------------------

    Template Name: Furnitech Furniture Bootstrap 4 Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
    02. Header CSS
    03. Hero CSS
    04. About CSS
    05. Banner CSS
    06. Feature CSS
    07. Product CSS
    08. Testimonial CSS
    09. Cart CSS
    10. Checkout CSS
    11. Compare CSS
    12. My Account CSS
    13. Login Register CSS
    14. Faq CSS
    15. Blog CSS
    16. Comment CSS
    17. Sidebar CSS
    18. Brand CSS
    19. Contact CSS
    20. Newsletter CSS
    21. Modal CSS
    22. 404 CSS
    23. Footer CSS

-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common';
@import 'header';
@import 'hero';
@import 'about';
@import 'banner';
@import 'feature';
@import 'product';
@import 'testimonial';
@import 'cart';
@import 'checkout';
@import 'compare';
@import 'my_account';
@import 'login_register';
@import 'faq';
@import 'blog';
@import 'comment';
@import 'sidebar';
@import 'brand';
@import 'contact';
@import 'newsletter';
@import 'modal';
@import '404';
@import 'footer';