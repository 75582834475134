/*---------------------------------------
    12. My Account CSS
-----------------------------------------*/

.myaccount-tab-menu {
    flex-direction: column;
    background-color: $white;
  
    & a {
        border: 1px solid #eeeeee;
        border-bottom: none;
        color: $body-color;
        font-weight: 500;
        font-size: 15px;
        display: block;
        padding: 15px 15px 13px;
        text-transform: uppercase;
  
        &:last-child {
            border-bottom: 1px solid #eeeeee;
        }
  
        &:hover,
        &.active {
            background-color: $theme-color;
            color: $white;
        }
  
        & i {
            font-size: 14px;
            text-align: center;
            width: 25px;
        }
    }
  }
  
  /*-- My Account Content -*/
  .myaccount-content {
    background-color: $white;
    font-size: 15px;
    border: 1px solid #eeeeee;
    padding: 30px;
  
    @media #{$small-mobile} {
        padding: 20px 15px;
    }
  
    & h3 {
        border-bottom: 1px dashed #eeeeee;
        padding-bottom: 10px;
        margin-bottom: 25px;
    }
  
    & .welcome {
        & a {
            color: $black;
  
            &:hover {
                color: $theme-color;
            }
        }
  
        & strong {
            font-weight: 600;
        }
    }
  
    a.edit-address-btn {
        border-color: $heading-color;
  
        i {
            padding-right: 5px;
        }
  
        &:hover {
            background-color: $theme-color;
            border-color: $theme-color;
        }
    }
  
    button.save-change-btn {
        background: none;
        border: none;
        font-weight: 400;
        text-transform: uppercase;
        color: #ffffff;
        background-color: $heading-color;
        border-color: $heading-color;
        width: 140px;
        padding: 10px 0;  
        border-radius: 3px;
  
        &:hover {
            background-color: $theme-color;
        }
    }
  }
  
  /*-- My Account Table -*/
  .myaccount-table {
    white-space: nowrap;
    font-size: 15px;
  
    & table,
    & .table {
        & th {
            padding: 10px;
            font-weight: 600;
        }
  
        & td {
            padding: 20px 10px;
            vertical-align: middle;
  
            a{
                &:hover{
                    color: $white;
                }
            }
        }
    }
  }
  
  .saved-message {
    border-top: 3px solid $theme-color;
    border-top: 3px solid $theme-color;
    border-radius: 5px 5px 0 0;
    font-weight: 600;
    font-size: 13px;
    padding: 20px;
  }
  
  /*-- My Account Details Form -*/
  .account-details-form {
    & h4 {
        margin: 0;
    }
  
    & input {
        display: block;
        width: 100%;
        border: 1px solid #ebebeb;
        border-radius: 0;
        line-height: 24px;
        padding: 11px 25px;
        color: #656565;
    }
  }
  