/*----------------------------------------*/
/*  20. Newsletter CSS
/*----------------------------------------*/

.newsletter-content{
    // Responsive
    @media #{$tablet-device, $large-mobile}{
        text-align: center;
        margin-bottom: 20px;
    }
    & h2{
        font-size: 36px;
        line-height: 1;
        font-weight: 700;
        margin-bottom: 10px;
        // Responsive
        @media #{$desktop-device}{
            font-size: 34px;
        }
        @media #{$large-mobile}{
            font-size: 24px;
        }
    }
    & p{
        max-width: 300px;
         // Responsive
        @media #{$tablet-device, $large-mobile}{
           margin: auto;
        }
    }
}

.newsletter-form{   
    position: relative;
    border: 2px solid $heading-color;
    width: 620px;
    max-width: 100%;
    margin: 0 auto;

    input{
        width: 100%;
        border: none;
        padding: 10px 20px;
        padding-right: 200px;
        line-height: 30px;
        height: 55px;
        color: $heading-color;
        font-size: 15px;
        background-color: transparent;

        @media #{$desktop-device}{
            padding-right: 160px;
        }
        @media #{$large-mobile}{
            height: 40px;
            padding-right: 150px;
        }

        @media #{$extra-small-mobile}{
            padding-right: 128px;
            font-size: 11px;
        }
    }

    button{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;   
        background: none;
        border: none;
        width: 200px;
        border-left: 2px solid $heading-color;
        font-size: 13px;
        font-weight: 600;
        color: $heading-color;
        // Responsive
        @media #{$desktop-device}{
            width: 150px;
        }
        @media #{$large-mobile}{
            width: 150px;
        }

        @media #{$extra-small-mobile}{
            width: 100px;
            font-size: 11px;
        }

        &:hover{
            color: $theme-color;
        }
    }
}