/*-----------------------------------------------------------------------------------

    Template Name: Furnitech Furniture Bootstrap 4 Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
    02. Header CSS
    03. Hero CSS
    04. About CSS
    05. Banner CSS
    06. Feature CSS
    07. Product CSS
    08. Testimonial CSS
    09. Cart CSS
    10. Checkout CSS
    11. Compare CSS
    12. My Account CSS
    13. Login Register CSS
    14. Faq CSS
    15. Blog CSS
    16. Comment CSS
    17. Sidebar CSS
    18. Brand CSS
    19. Contact CSS
    20. Newsletter CSS
    21. Modal CSS
    22. 404 CSS
    23. Footer CSS

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
/*-- Common Style --*/
*, *::after, *::before {
  box-sizing: border-box;
}

html, body, .site-wrapper {
  height: 100%;
}

body {
  background-color: #ffffff;
  font-size: 15px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  visibility: visible;
  font-family: "Montserrat", sans-serif;
  color: #151515;
  font-weight: 400;
  position: relative;
}

body[data-rtl="rtl"] {
  direction: rtl;
  text-align: right;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  color: #151515;
  font-weight: 400;
  margin-top: 0;
  line-height: 1.2;
}

h1 {
  font-size: 36px;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input, span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #151515;
}

button, input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section, .main-wrapper {
  float: left;
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid #eeeeee;
}

.bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#scrollUp {
  background: #151515;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
  bottom: 25px;
  right: 25px;
  color: #ffffff;
  text-align: center;
  font-size: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#scrollUp:hover {
  background: #151515;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
  .row-five-column > [class*="col-xl-"] {
    max-width: 20%;
    flex: 0 0 20%;
  }
}

@media only screen and (max-width: 575px) {
  .container {
    max-width: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .container {
    max-width: 300px;
  }
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  margin: 0 !important;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder, textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*--
    - Background Color
------------------------------------------*/
.bg-white {
  background-color: #ffffff !important;
}

.bg-gray {
  background-color: #f8f8f8 !important;
}

.bg-gray-two {
  background-color: #f3f1ec !important;
}

.bg-black {
  background-color: #111111;
  opacity: .95;
}

.bg-dark {
  background-color: #151515 !important;
}

.bg-dark-two {
  background-color: #1c2535 !important;
}

.bg-dark-three {
  background-color: #1d2736 !important;
}

.bg-dark-four {
  background-color: #1d2634 !important;
}

.bg-skyblue {
  background-color: #dfecf7 !important;
}

.bg-theme {
  background-color: #151515 !important;
}

/*-- 
    - Tab Content & Pane Fix
------------------------------------------*/
.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  transform: scale(0.8);
  transition: all .6s ease-in-out;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
  transform: scale(1);
}

/*-- 
    - Main Wrapper
------------------------------------------*/
/*-- 
    - Section Title
------------------------------------------*/
.section-title h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 1;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 23px;
  }
}

.section-title span {
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  display: block;
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .section-title span {
    font-size: 14px;
  }
}

.section-title p {
  margin-top: 15px;
  line-height: 22px;
}

.section-title.full-width p {
  margin-bottom: 35px;
  max-width: 100%;
}

.section-title.text-center {
  text-align: center;
  background-position: top center;
}

.section-title.text-center p {
  margin-left: auto;
  margin-right: auto;
}

.section-title.text-left {
  text-align: left;
  background-position: top left;
}

.section-title.text-left p {
  margin-left: 0;
  margin-right: auto;
}

.section-title.text-right {
  text-align: right;
  background-position: top right;
}

.section-title.text-right p {
  margin-left: auto;
  margin-right: 0;
}

.section-title.color-white h1 {
  color: #ffffff;
}

.section-title.color-white p {
  color: #aaaaaa;
}

.product-tab ul {
  justify-content: center;
}

.product-tab ul li a {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  color: #686868;
}

.product-tab ul li a::after {
  content: '-';
  margin: 0 7px;
}

.product-tab ul li a:hover, .product-tab ul li a.active {
  color: #151515;
  font-weight: 700;
}

.product-tab ul li:last-child a::after {
  display: none;
}

/*-- 
    - Button
------------------------------------------*/
.btn {
  background-color: #020202;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 500;
  padding: 12px 24px;
  text-transform: uppercase;
  border-radius: 0px;
  position: relative;
  border-radius: 50px;
  transition: all 0.3s ease 0s;
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn:hover {
  background-color: #151515;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .btn {
    font-size: 14px;
    line-height: 23px;
    height: 45px;
    padding: 10px 25px;
  }
  .btn:hover::before {
    left: 6px;
    top: 6px;
  }
  .btn:hover::after {
    left: -6px;
    top: -6px;
  }
}

/* Pentagon Icon CSS */
.pentagon-icon .icon {
  position: relative;
  width: 50px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #151515;
  border-radius: 6px;
  margin: 0 20px;
}

@media only screen and (max-width: 767px) {
  .pentagon-icon .icon {
    width: 38px;
    height: 57px;
  }
}

.pentagon-icon .icon::before, .pentagon-icon .icon::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #151515;
  transform: rotate(60deg);
  z-index: -1;
  border-radius: 6px;
}

.pentagon-icon .icon::after {
  transform: rotate(-60deg);
}

.pentagon-icon .icon i {
  font-size: 26px;
  color: #151515;
}

/*-- 
    - Page Banner Section
------------------------------------------*/
.page-banner-section {
  padding: 120px 0 130px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .page-banner-section {
    padding: 100px 0 110px;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .page-banner-section {
    padding: 90px 0 100px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner-section {
    padding: 80px 0 90px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner-section {
    padding: 70px 0 80px;
    width: 100%;
  }
}

/*-- Page Banner --*/
.page-banner h2 {
  font-size: 39px;
  font-weight: 500;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner h2 {
    font-size: 26px;
  }
}

/*-- Page Breadcrumb --*/
.page-breadcrumb {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.page-breadcrumb li {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: "Montserrat", sans-serif;
  line-height: 1;
  margin-top: 10px;
  color: #151515;
}

@media only screen and (max-width: 575px) {
  .page-breadcrumb li {
    font-size: 13px;
  }
}

.page-breadcrumb li::after {
  content: "/";
  margin: 0 6px;
  color: #151515;
}

.page-breadcrumb li:last-child::after {
  display: none;
}

.page-breadcrumb li a {
  color: #151515;
}

.page-breadcrumb li a:hover {
  color: #151515;
}

/*-- 
    - Page Pagination
------------------------------------------*/
.page-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.page-pagination li {
  margin: 0 15px 0 0;
  vertical-align: middle;
}

.page-pagination li:last-child {
  margin-right: 0;
}

.page-pagination li a {
  height: 30px;
  line-height: 30px;
  min-width: 0;
  padding: 0 5px;
}

.page-pagination li:hover a {
  color: #151515;
}

.page-pagination li.active a {
  color: #151515;
}

.grid-filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.grid-filter button {
  background-color: transparent;
  color: #151515;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  margin: 0 10px 10px;
  text-transform: capitalize;
  line-height: 1;
  padding-bottom: 5px;
  position: relative;
}

.grid-filter button::before {
  content: "";
  height: 6px;
  width: 0;
  position: absolute;
  left: 0;
  bottom: 4px;
  background-color: #151515;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.grid-filter button:hover::before, .grid-filter button.active::before {
  width: 100%;
}

.grid-filter.center {
  justify-content: center;
}

.grid-filter.center button {
  margin: 0 10px 10px;
}

.grid-filter.left {
  justify-content: flex-start;
}

.grid-filter.left button {
  margin-left: 0;
  margin-right: 20px;
  margin-bottom: 10px;
}

.grid-filter.left button:last-child {
  margin-right: 0;
}

.grid-filter.right {
  justify-content: flex-end;
}

.grid-filter.right button {
  margin-left: 20px;
  margin-right: 0;
  margin-bottom: 10px;
}

.grid-filter.right button:last-child {
  margin-left: 0;
}

.slick-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  border: none;
  background-color: #151515;
  color: #ffffff;
  padding: 10px;
  width: 44px;
  height: 44px;
  border-radius: 0;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  opacity: 0;
}

.slick-slider .slick-arrow::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #151515;
  background-color: transparent;
  transition: all 0.3s ease 0s;
}

.slick-slider .slick-arrow i {
  font-size: 24px;
  line-height: 20px;
  display: block;
}

.slick-slider .slick-arrow.slick-prev {
  left: 0;
  margin-left: 40px;
}

.slick-slider .slick-arrow.slick-next {
  right: 0;
  margin-right: 40px;
}

.slick-slider:hover .slick-arrow {
  opacity: 1;
}

.slick-slider:hover .slick-arrow.slick-prev {
  margin-left: 20px;
}

.slick-slider:hover .slick-arrow.slick-next {
  margin-right: 20px;
}

.slick-slider .slick-dots {
  width: 100%;
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
  padding-right: 15px;
}

.slick-slider .slick-dots li {
  margin: 0 5px;
}

.slick-slider .slick-dots li button {
  display: block;
  padding: 0;
  width: 15px;
  height: 15px;
  border: 3px solid #151515;
  background-color: #151515;
  text-indent: -9999px;
  border-radius: 50px;
}

@media only screen and (max-width: 767px) {
  .slick-slider .slick-dots li button {
    border-width: 2px;
    width: 10px;
    height: 10px;
  }
}

.slick-slider .slick-dots li.slick-active button {
  width: 30px;
  background-color: #f1f1f1;
}

@media only screen and (max-width: 767px) {
  .slick-slider .slick-dots li.slick-active button {
    width: 20px;
  }
}

.gallery-item {
  position: relative;
}

.gallery-item::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #151515;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.gallery-item img {
  width: 100%;
}

.gallery-item .plus {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 3;
  opacity: 0;
}

.gallery-item .plus::before, .gallery-item .plus::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ffffff;
  transition: all 0.3s ease 0s;
}

.gallery-item .plus::before {
  width: 150px;
  height: 1px;
}

.gallery-item .plus::after {
  width: 1px;
  height: 150px;
}

.gallery-item:hover::before {
  opacity: 0.75;
}

.gallery-item:hover .plus {
  opacity: 1;
}

.gallery-item:hover .plus::before {
  width: 40px;
}

.gallery-item:hover .plus::after {
  height: 40px;
}

blockquote.blockquote {
  background-color: #f1f2f3;
  padding: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

blockquote.blockquote::before {
  position: absolute;
  content: url(../images/icons/quote-left.png);
  left: -5px;
  top: -10px;
  z-index: -1;
  opacity: 0.07;
}

blockquote.blockquote p {
  font-size: 18px;
  font-style: italic;
}

blockquote.blockquote .author {
  font-size: 14px;
  display: block;
  line-height: 18px;
}

/*----------------------------------------*/
/*  02. Header CSS
/*----------------------------------------*/
header.header {
  background-color: #ffffff;
  position: static;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

header.header.is-sticky {
  position: fixed !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff !important;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: slideInDown;
  animation-duration: 0.5s;
}

header.header.is-sticky .header-top {
  display: none;
}

header.header.is-sticky .header-middle {
  display: none;
}

.header-absolute {
  position: absolute !important;
  background-color: transparent !important;
}

/*Header Search And Cart Area CSS*/
.header-search-cart-area {
  text-align: right;
}

.header-search-cart-area > ul > li {
  display: inline-block;
  list-style: none;
  position: relative;
}

.header-search-cart-area > ul > li > a {
  font-size: 24px;
  height: 40px;
  width: 40px;
  display: block;
  line-height: 40px;
  text-align: center;
  color: #363636;
}

.header-search-cart-area > ul > li > a i {
  font-size: 24px;
}

.header-search-cart-area > ul > li > a:hover {
  color: #151515;
}

.header-search-cart-area ul > li.mini-cart > a {
  position: relative;
  width: auto;
}

.cart-quantity {
  position: absolute;
  width: 23px;
  height: 23px;
  background: #151515;
  font-size: 14px;
  text-align: center;
  border-radius: 100%;
  line-height: 23px;
  color: #fff;
  font-weight: 500;
  top: -3px;
  left: 16px;
}

.mini-cart-total {
  font-size: 18px;
  color: #151515;
  line-height: 24px;
  font-weight: 500;
  margin-left: 5px;
}

.header-cart {
  position: absolute;
  width: 320px;
  background: #fff;
  right: 0;
  top: 120%;
  padding: 5px 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
  z-index: 9999999;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-search-cart-area ul > li.mini-cart:hover .header-cart {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
  top: 100%;
}

.cart-img {
  float: left;
  width: 30%;
  margin-right: 10px;
  position: relative;
}

.cart-content {
  float: left;
  width: 65%;
}

ul.cart-items > li {
  list-style: none;
  display: block;
  overflow: hidden;
  padding: 20px 0;
  border-bottom: 1px solid #ededed;
  position: relative;
  text-align: left;
}

.cart-content > span {
  color: #222;
  font-weight: 400;
  font-size: 16px;
}

.cart-content > span.product-price {
  color: #151515;
  font-size: 16px;
}

.product-name {
  color: #222;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 20px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  width: 155px;
}

.cart-item-remove > a {
  position: absolute;
  color: #222;
  right: 2px;
  top: 18px;
  display: inline-block;
  left: auto;
  font-size: 20px;
}

.cart-item-remove > a:hover {
  color: #151515;
}

.cart-total {
  overflow: hidden;
  padding: 20px 0;
  text-align: left;
  border-bottom: 1px solid #ededed;
}

.cart-total > h5 {
  color: #3e3d43;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
}

.cart-btn {
  padding: 20px;
  background-color: #fff;
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -5px;
}

.cart-btn > a {
  display: block;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  padding: 10px 18px;
  text-transform: uppercase;
  font-weight: 700;
}

.cart-btn > a:first-child {
  margin-bottom: 10px;
}

.cart-btn > a:hover {
  background: #151515;
  border-color: #151515;
  color: #fff;
}

/*Currency CSS*/
.currency-dropdown {
  position: absolute;
  top: 140%;
  right: 0;
  min-width: 300px;
  background: #fff;
  padding: 40px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  z-index: 99;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-search-cart-area ul > li.currency-menu:hover .currency-dropdown {
  top: 100%;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
}

.currency-dropdown > li {
  display: block;
  list-style: none;
  margin-bottom: 15px;
  line-height: 1;
}

.currency-dropdown > li:last-child {
  margin-bottom: 0;
}

.currency-dropdown > li > a {
  text-transform: uppercase;
  font-size: 14px;
  color: #292929;
  display: block;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 25px;
}

.currency-dropdown > li > ul {
  padding-left: 15px;
}

.currency-dropdown > li > ul > li {
  list-style: none;
}

.currency-dropdown > li > ul > li > a {
  color: #333;
  padding: 10px 0;
  display: block;
  color: #333;
  padding: 10px 0;
  display: block;
}

.currency-dropdown > li > ul > li > a:hover {
  color: #151515;
}

.currency-dropdown > li > ul > li.active > a {
  color: #151515;
}

.currency-dropdown > li > ul > li > a > img {
  padding-right: 5px;
}

/*-- Header Search --*/
.header-search {
  margin-left: 15px;
  display: flex;
  align-items: center;
  position: relative;
}

.header-search.red-color button {
  color: #ffffff;
}

.header-search.white-color button {
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-search {
    margin-left: 0;
    margin-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .header-search {
    margin-left: 0;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .header-search {
    margin-left: 0;
    margin-right: 30px;
  }
}

.header-search-toggle {
  background-color: transparent;
  border: none;
  color: #151515;
  padding: 0;
  line-height: 30px;
  width: 40px;
  height: 30px;
}

.header-search-toggle i {
  font-size: 15px;
}

.header-search-toggle.open i {
  font-size: 30px;
}

/*Main Search CSS*/
.main-search-active {
  background: rgba(0, 0, 0, 0.92) none repeat scroll 0 0;
  width: 100%;
  min-height: 100vh;
  padding: 32px 46px 39px;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  z-index: 9999;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .main-search-active {
    padding: 32px 20px 39px;
  }
}

.sidebar-search-icon {
  position: absolute;
  top: 50px;
  right: 70px;
  color: #ffffff;
  font-size: 60px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .sidebar-search-icon {
    right: 0;
  }
}

.sidebar-search-icon button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 50px;
  line-height: 1;
  padding: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.sidebar-search-input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  display: inline-block;
  padding: 0 50px;
  width: 75%;
  text-align: center;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out .3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .sidebar-search-input {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-search-input {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-search-input {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-search-input {
    padding: 0 10px;
    width: 100%;
  }
}

.sidebar-search-input form .form-search {
  position: relative;
}

.sidebar-search-input form .form-search input {
  padding: 10px 0;
  border: 0;
  border-bottom: 5px solid transparent;
  background-color: transparent;
  font-size: 200px;
  width: 100%;
  height: auto;
  color: #ffffff;
  transform: scale3d(0, 1, 1);
  transform-origin: 0 50%;
  transition: transform .3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .sidebar-search-input form .form-search input {
    font-size: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-search-input form .form-search input {
    font-size: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-search-input form .form-search input {
    font-size: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-search-input form .form-search input {
    font-size: 50px;
  }
}

.sidebar-search-input form .form-search input::placeholder, .sidebar-search-input form .form-search input::-webkit-input-placeholder, .sidebar-search-input form .form-search input::-moz-placeholder, .sidebar-search-input form .form-search input:-ms-input-placeholder, .sidebar-search-input form .form-search input::placeholder {
  color: #ffffff;
}

.sidebar-search-input form .form-search button {
  background-color: transparent;
  border: medium none;
  color: #ffffff;
  font-size: 30px;
  padding: 0;
  position: absolute;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.sidebar-search-input p {
  font-size: 90%;
  font-weight: bold;
  display: block;
  padding: 11px 0;
  text-align: right;
  opacity: 0;
  line-height: 1.6;
  color: #ffffff;
  transform: translate3d(0, 50px, 0);
  transition: opacity .4s, transform .4s;
}

@media only screen and (max-width: 767px) {
  .sidebar-search-input p {
    font-size: 14px;
  }
}

.main-search-active.inside {
  opacity: 1;
  visibility: visible;
  z-index: 9999999;
}

.main-search-active.inside .sidebar-search-input {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.main-search-active.inside .sidebar-search-input input {
  transform: scale3d(1, 1, 1);
  transition: .5s ease-out .3s;
  border-bottom-color: #ffffff;
}

.main-search-active.inside .sidebar-search-input p {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/*Main Search CSS*/
/*-- Main Menu --*/
.main-menu > ul {
  display: flex;
}

.main-menu > ul > li {
  position: relative;
}

.main-menu > ul > li > a {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #151515;
  text-transform: capitalize;
  display: block;
  padding: 42px 0 42px 0;
  margin: 0 37px 0 0;
  position: relative;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > ul > li > a {
    padding: 30px 0 30px 0;
    margin: 0 22px 0 0;
    font-size: 16px;
  }
}

.main-menu > ul > li.has-dropdown > a::after {
  content: "\f107";
  font-family: Fontawesome;
  line-height: 30px;
  margin-left: 3px;
}

.main-menu > ul > li.active > a, .main-menu > ul > li:hover > a {
  color: #151515;
}

.main-menu > ul > li:hover > .sub-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.main-menu > ul > li:hover > .mega-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.main-menu > ul > li:last-child .sub-menu {
  left: auto;
  right: 0;
}

.main-menu > ul > li:last-child .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}

.main-menu > ul > li:last-child .sub-menu .sub-menu .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.main-menu > ul > li:nth-last-child(-n+3) .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.main-menu > ul > li:nth-last-child(-n+3) .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-two > ul > li > a {
    padding: 30px 12px;
    margin: 0;
  }
}

/*-- Sub Menu --*/
.sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 30px;
  padding: 20px 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease 0s;
  width: 210px;
  z-index: -99;
  opacity: 0;
  visibility: hidden;
}

.sub-menu li {
  margin-bottom: 5px;
  position: relative;
}

.sub-menu li:last-child {
  margin-bottom: 0;
}

.sub-menu li a {
  font-family: "Montserrat", sans-serif;
  color: #151515;
  display: block;
  font-size: 14px;
  line-height: 35px;
  font-weight: 400;
  padding: 0 20px;
  text-align: left;
}

.sub-menu li.has-dropdown > a::after {
  content: "\f105";
  font-family: Fontawesome;
  line-height: 30px;
  float: right;
}

.sub-menu li.active > a {
  color: #151515;
}

.sub-menu li .sub-menu {
  left: 100%;
  top: 0;
  margin-left: 0;
}

.sub-menu li:hover > a {
  color: #151515;
  padding-left: 25px;
}

.sub-menu li:hover > .sub-menu {
  margin-top: -10px;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.sub-menu li .sub-menu {
  left: 100%;
  margin-left: 0;
  top: 0;
}

.sub-menu li .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.sub-menu li .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}

/*-- Mega Menu --*/
.mega-menu.four-column {
  width: 800px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu.four-column {
    width: 820px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-menu.four-column {
    width: 820px;
  }
}

.mega-menu {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 30px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-bottom: 2px solid #151515;
  text-align: left;
  z-index: 999;
  width: 800px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu {
    width: 770px;
    left: -40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-menu {
    width: 770px;
    left: -160px;
  }
}

.mega-menu > li {
  width: 25%;
  padding: 0px 13px;
  margin: 34px 0;
  float: left;
  border-left: 1px solid #eeeeee;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu > li {
    padding: 0 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-menu > li {
    padding: 0 5px;
  }
}

.mega-menu > li:first-child {
  border-left: 0;
}

.mega-menu > li > a {
  margin: 0 0 12px 8px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 20px;
  color: #151515;
  text-align: left;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu > li > a {
    margin: 0 0 12px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-menu > li > a {
    margin: 0 0 12px 20px;
  }
}

.mega-menu > li > ul > li {
  display: block;
}

.mega-menu > li > ul > li > a {
  padding: 0px 8px;
  display: block;
  font-size: 13px;
  line-height: 35px;
  font-weight: 400;
  color: #151515;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu > li > ul > li > a {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-menu > li > ul > li > a {
    padding: 0 20px;
  }
}

.mega-menu > li > ul > li > a:hover {
  color: #151515;
}

/* offcanvas mobile menu */
.header-mobile-navigation {
  padding: 20px 0;
}

.header-mobile-navigation .mobile-navigation ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-mobile-navigation .mobile-navigation ul li {
  margin-left: 20px;
}

.header-mobile-navigation .mobile-navigation ul li:first-child {
  margin-left: 0;
}

.header-mobile-navigation .mobile-navigation ul li a {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  color: #151515;
}

.header-mobile-navigation .mobile-navigation .header-cart-icon {
  position: relative;
  display: inline-block;
}

.header-mobile-navigation .mobile-navigation .header-cart-icon a {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  color: #151515;
}

.header-mobile-navigation .mobile-navigation .header-cart-icon a span {
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: -5px;
  left: 50%;
  width: 18px;
  height: 18px;
  color: #151515;
  border-radius: 100%;
}

.offcanvas-widget-area {
  margin-bottom: 35px;
}

@media only screen and (max-width: 479px) {
  .offcanvas-widget-area {
    margin-bottom: 30px;
  }
}

.off-canvas-contact-widget {
  margin-bottom: 20px;
}

.off-canvas-contact-widget .header-contact-info {
  flex-basis: 33.33%;
}

.off-canvas-contact-widget .header-contact-info .header-contact-info-list li {
  display: inline-block;
  margin-right: 25px;
}

.off-canvas-contact-widget .header-contact-info .header-contact-info-list li i {
  font-size: 14px;
  margin-right: 5px;
}

.off-canvas-contact-widget .header-contact-info .header-contact-info-list li a {
  font-weight: 400;
  line-height: 22px;
  color: #151515;
}

.off-canvas-contact-widget .header-contact-info .header-contact-info-list li a:hover {
  color: #151515;
}

.off-canvas-contact-widget .header-contact-info .header-contact-info-list li:last-child {
  margin-right: 0;
}

.offcanvas-mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  max-width: 100%;
  height: 100vh;
  z-index: 9999;
  transform: translateX(100%);
  padding-left: 60px;
  transition: 0.6s;
}

.offcanvas-mobile-menu.active {
  transform: translateX(0);
}

.offcanvas-mobile-menu.inactive {
  transform: translateX(calc(100% + 60px));
}

.offcanvas-menu-close {
  position: absolute;
  left: 0;
  top: 0;
  background: #343538;
  z-index: 9;
  width: 60px;
  height: 60px;
  color: #fff;
  line-height: 58px;
  text-align: center;
  font-size: 18px;
}

@media only screen and (max-width: 479px) {
  .offcanvas-menu-close {
    width: 50px;
    height: 50px;
    line-height: 48px;
    left: 10px;
    font-size: 18px;
  }
}

.offcanvas-menu-close:hover, .offcanvas-menu-close:focus {
  color: #ffffff;
}

.offcanvas-menu-close i {
  transition: 0.3s;
  transform: rotate(0);
}

.offcanvas-menu-close:hover i {
  transform: rotate(-90deg);
}

.offcanvas-wrapper {
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
}

.offcanvas-mobile-search-area {
  background-color: #e6e6e6;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  z-index: 9;
  margin-left: 60px;
}

.offcanvas-mobile-search-area input {
  width: 100%;
  font-size: 16px;
  display: block;
  padding: 9px 25px;
  padding-right: 35px;
  color: #222;
  background: #e6e6e6;
  border: none;
}

@media only screen and (max-width: 479px) {
  .offcanvas-mobile-search-area input {
    font-size: 14px;
    padding: 5px 15px;
    padding-right: 35px;
  }
}

.offcanvas-mobile-search-area button {
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  padding: 0;
}

.offcanvas-mobile-search-area button i {
  font-size: 18px;
  line-height: 40px;
}

.offcanvas-inner-content {
  padding: 90px 35px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 479px) {
  .offcanvas-inner-content {
    padding: 70px 25px 0;
  }
}

.offcanvas-navigation {
  margin-bottom: 50px;
}

.offcanvas-navigation > ul > li > a {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 10px 0;
  color: #151515;
}

.offcanvas-navigation > ul > li > a:hover {
  color: #151515;
}

@media only screen and (max-width: 479px) {
  .offcanvas-navigation > ul > li > a {
    font-size: 14px;
    line-height: 20px;
  }
}

.offcanvas-navigation ul.submenu2 {
  margin-left: 25px;
}

.offcanvas-navigation ul.submenu2 > li > a {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  padding: 10px 0;
}

.offcanvas-navigation ul.submenu2 > li > a:hover {
  color: #151515;
}

@media only screen and (max-width: 479px) {
  .offcanvas-navigation ul.submenu2 > li > a {
    font-size: 13px;
    line-height: 18px;
  }
}

.offcanvas-navigation ul li.menu-item-has-children {
  position: relative;
  display: block;
}

.offcanvas-navigation ul li.menu-item-has-children a {
  display: block;
}

.offcanvas-navigation ul li.menu-item-has-children.active > .menu-expand i:before {
  transform: rotate(0);
}

.offcanvas-navigation ul li.menu-item-has-children .menu-expand {
  position: absolute;
  right: auto;
  left: 95%;
  top: -5px;
  width: 30px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
}

.offcanvas-navigation ul li.menu-item-has-children .menu-expand i {
  display: block;
  margin-top: 25px;
  border-bottom: 1px solid;
  position: relative;
  width: 10px;
  transition: all 250ms ease-out;
}

.offcanvas-navigation ul li.menu-item-has-children .menu-expand i:before {
  width: 100%;
  content: "";
  border-bottom: 1px solid;
  display: block;
  position: absolute;
  top: 0;
  transform: rotate(90deg);
}

.off-canvas-widget-social a {
  margin: 0 10px;
  font-size: 14px;
}

.off-canvas-widget-social a:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 479px) {
  .off-canvas-widget-social a {
    margin: 0 10px;
  }
}

.off-canvas-widget-social a:hover {
  color: #151515;
}

/* offcanvas settings */
.offcanvas-settings .offcanvas-navigation > ul > li > a {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 0;
}

.offcanvas-settings .offcanvas-navigation > ul > li.menu-item-has-children .menu-expand {
  height: 30px;
  top: -15px;
  margin-top: 0;
}

.offcanvas-settings .offcanvas-navigation ul.sub-menu > li > a {
  padding: 5px 0;
}

/*----------------------------------------*/
/*  03. Hero CSS
/*----------------------------------------*/
/*-- Hero Slider --*/
.hero-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  border: none;
  background-color: transparent;
  color: #151515;
  padding: 10px;
  width: 54px;
  height: 54px;
  border-radius: 0;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  opacity: 0;
}

.hero-slider .slick-arrow::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #151515;
  background-color: transparent;
  transition: all 0.3s ease 0s;
}

.hero-slider .slick-arrow i {
  font-size: 24px;
  line-height: 30px;
  display: block;
}

.hero-slider .slick-arrow.slick-prev {
  left: 40px;
  margin-left: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

.hero-slider .slick-arrow.slick-next {
  right: 40px;
  margin-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

.hero-slider .slick-arrow:hover {
  background-color: #151515;
  color: #ffffff;
}

.hero-slider .slick-arrow:hover::before {
  margin-left: 6px;
  margin-top: 6px;
}

.hero-slider:hover .slick-arrow {
  opacity: 1;
}

.hero-slider:hover .slick-arrow.slick-prev {
  margin-left: 0;
}

.hero-slider:hover .slick-arrow.slick-next {
  margin-right: 0;
}

.hero-slider .slick-dots {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider .slick-dots {
    bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider .slick-dots {
    bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider .slick-dots {
    bottom: 15px;
  }
}

.hero-slider .slick-dots li {
  margin: 0 5px;
}

.hero-slider .slick-dots li button {
  display: block;
  padding: 0;
  width: 15px;
  height: 15px;
  border: 3px solid #151515;
  background-color: #151515;
  text-indent: -9999px;
  border-radius: 50px;
}

@media only screen and (max-width: 767px) {
  .hero-slider .slick-dots li button {
    border-width: 2px;
    width: 10px;
    height: 10px;
  }
}

.hero-slider .slick-dots li.slick-active button {
  width: 30px;
  background-color: #151515;
}

@media only screen and (max-width: 767px) {
  .hero-slider .slick-dots li.slick-active button {
    width: 20px;
  }
}

/*-- Hero Item --*/
.hero-item {
  width: 100%;
  height: 800px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.hero-item.large-height {
  height: 900px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-item.large-height {
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-item.large-height {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-item.large-height {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-item.large-height {
    height: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-item {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-item {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-item {
    height: 400px;
  }
}

/*-- Hero Content For Background Video --*/
.hero-content {
  text-align: center;
}

.hero-content h2 {
  font-size: 32px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .hero-content h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content h2 {
    font-size: 18px;
  }
}

.hero-content h1 {
  font-size: 90px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .hero-content h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content h1 {
    font-size: 44px;
  }
}

.hero-content h3 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .hero-content h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content h3 {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content h3 {
    font-size: 14px;
  }
}

/*-- Hero Content Two For Hero Slider --*/
.hero-content-2 {
  margin-top: 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-2 {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 {
    margin-top: 30px;
  }
}

.hero-content-2.margin-top {
  margin-top: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-content-2.margin-top {
    margin-top: 140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-2.margin-top {
    margin-top: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2.margin-top {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2.margin-top {
    margin-top: 30px;
  }
}

.hero-content-2.left {
  text-align: left;
}

.hero-content-2.center {
  text-align: center;
}

.hero-content-2.right {
  text-align: right;
}

.hero-content-2 > * {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeOutUp;
}

.hero-content-2 h2 {
  font-size: 56px;
  line-height: 64px;
  font-weight: 600;
  text-transform: capitalize;
  color: #151515;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-2 h2 {
    font-size: 50px;
    line-height: 56px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 h2 {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 h2 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 10px;
  }
}

.hero-content-2 p {
  font-size: 16px;
  color: #ffffff;
  max-width: 650px;
  margin-bottom: 40px;
}

.hero-content-2 .btn {
  font-size: 18px;
}

/*-- Slide Content In Animation --*/
.slick-active {
  /*-- Hero Content Two For Hero Slider --*/
}

.slick-active .hero-content-2 > * {
  animation-name: fadeInUp;
}

.slick-active .hero-content-2 > *:nth-child(1) {
  animation-delay: 0.5s;
}

.slick-active .hero-content-2 > *:nth-child(2) {
  animation-delay: 1s;
}

.slick-active .hero-content-2 > *:nth-child(3) {
  animation-delay: 1.5s;
}

.slick-active .hero-content-2 > *:nth-child(4) {
  animation-delay: 2s;
}

.slick-active .hero-content-2 > *:nth-child(5) {
  animation-delay: 2.5s;
}

.slick-active .hero-content-2 > *:nth-child(6) {
  animation-delay: 3s;
}

/*----------------------------------------*/
/*  04. About CSS
/*----------------------------------------*/
/* About Page CSS */
.about-us-content span {
  display: block;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin-bottom: 10px;
}

.about-us-content span::before {
  position: absolute;
  content: '';
  width: 50px;
  height: 2px;
  background: #151515;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
}

.about-us-content h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: capitalize;
  color: #151515;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content h2 {
    font-size: 28px;
    margin-bottom: 10px;
    max-width: 470px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.about-us-content p {
  line-height: 22px;
  max-width: 470px;
}

.about-us-image {
  overflow: hidden;
}

.about-us-image.img-full img {
  transition: all 0.5s ease-in-out;
}

.about-us-img-wrapper:hover .about-us-image img {
  transform: scale(1.2);
}

.single-team {
  position: relative;
}

.single-team .team-image {
  transition: all 0.4s ease 0s;
}

.single-team .team-image a {
  display: block;
}

.single-team .team-image a img {
  width: 100%;
}

.single-team .team-content {
  bottom: 0;
  padding: 30px;
  position: absolute;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  text-align: center;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: 4;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-team .team-content {
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .single-team .team-content {
    padding: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .single-team .team-content {
    padding: 30px;
  }
}

.single-team .team-content .team-hover-info {
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
  padding: 30px 0;
  right: 30px;
  z-index: 2;
}

.single-team .team-content .team-hover-info h2 {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 14px;
  text-transform: capitalize;
}

.single-team .team-content .team-hover-info h2 a:hover {
  color: #ffffff;
}

.single-team .team-content .team-hover-info .team-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.single-team .team-content .team-hover-info .team-social li {
  list-style: none;
  padding: 0 17px;
}

.single-team .team-content .team-hover-info .team-social li > a > i {
  border: 0 solid transparent;
  border-radius: 100%;
  color: #fff;
  font-size: 14px;
  height: auto;
  line-height: 20px;
  width: auto;
}

.single-team .team-content .team-hover-info .team-social li > a:hover > i {
  color: #151515;
}

.single-team:hover .team-image img {
  filter: grayscale(100%);
  transition: all 0.4s ease 0s;
}

.single-team:hover .team-content {
  transform: scaleY(1);
}

/*----------------------------------------*/
/*  05. Banner CSS
/*----------------------------------------*/
/* Banner CSS */
.single-banner-item {
  position: relative;
  z-index: 2;
}

.single-banner-item .banner-image {
  position: relative;
  overflow: hidden;
}

.single-banner-item .banner-image a {
  display: block;
  width: 100%;
}

.single-banner-item .banner-image a img {
  width: 100%;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-banner-item .banner-content {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content {
    bottom: 30px;
  }
}

.single-banner-item .banner-content.tr-right {
  bottom: auto;
  top: 40px;
  right: 90px;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content.tr-right {
    top: 30px;
    right: 26px;
  }
}

.single-banner-item .banner-content.tl-left {
  left: 40px;
  right: auto;
  top: 40px;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content.tl-left {
    left: 15px;
    top: 25px;
  }
}

.single-banner-item .banner-content.color-white .title {
  color: #ffffff;
}

.single-banner-item .banner-content.color-white a {
  color: #ffffff;
}

.single-banner-item .banner-content .title {
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content .title {
    font-size: 24px;
    line-height: 28px;
  }
}

.single-banner-item .banner-content a {
  font-size: 16px;
  line-height: 32px;
  text-decoration: underline;
  font-weight: 500;
}

.single-banner-item .discount-banner-content {
  position: absolute;
  top: 40px;
  left: 40px;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .discount-banner-content {
    top: 20px;
    left: 20px;
  }
}

.single-banner-item .discount-banner-content h3 {
  font-size: 24px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .discount-banner-content h3 {
    font-size: 22px;
    line-height: 38px;
  }
}

.single-banner-item .discount-banner-content h3 span {
  font-size: 55px;
  color: #ff0000;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .discount-banner-content h3 span {
    font-size: 50px;
  }
}

.single-banner-item .sp-banner-content {
  padding-left: 145px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .sp-banner-content {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner-item .sp-banner-content {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner-item .sp-banner-content {
    padding-left: 15px;
  }
}

.single-banner-item .sp-banner-content.content-right {
  padding-left: 420px;
  padding-top: 60px;
  padding-bottom: 65px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .sp-banner-content.content-right {
    padding-top: 30px;
    padding-bottom: 35px;
    padding-left: 320px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner-item .sp-banner-content.content-right {
    padding-top: 55px;
    padding-bottom: 60px;
    padding-left: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner-item .sp-banner-content.content-right {
    padding-top: 55px;
    padding-bottom: 60px;
    padding-left: 10px;
  }
}

.single-banner-item .sp-banner-content .normat-text {
  display: block;
  font-size: 18px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .sp-banner-content .normat-text {
    font-size: 16px;
    line-height: 36px;
  }
}

.single-banner-item .sp-banner-content .title {
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .sp-banner-content .title {
    font-size: 24px;
    line-height: 28px;
  }
}

.single-banner-item .sp-banner-content .countdown-area {
  display: flex;
  align-items: flex-end;
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner-item .sp-banner-content .countdown-area {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner-item .sp-banner-content .countdown-area {
    margin-bottom: 10px;
  }
}

.single-banner-item .sp-banner-content .countdown-area .single-countdown {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .sp-banner-content .countdown-area .single-countdown {
    font-size: 18px;
    line-height: 1;
  }
}

.single-banner-item .sp-banner-content .countdown-area .single-countdown.big-font {
  font-size: 30px;
  line-height: 1;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .single-banner-item .sp-banner-content .countdown-area .single-countdown.big-font {
    font-size: 26px;
    line-height: 26px;
  }
}

.single-banner-item .sp-banner-content .countdown-area .single-countdown:nth-child(1)::before {
  display: none;
}

.single-banner-item .sp-banner-content .countdown-area .single-countdown:nth-child(2)::before {
  display: none;
}

.single-banner-item .sp-banner-content .countdown-area .single-countdown::before {
  content: ':';
  margin: 0 5px;
}

.single-banner-item .sp-banner-content a {
  font-size: 16px;
  line-height: 32px;
  text-decoration: underline;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .single-banner-item::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -1;
    opacity: 0.7;
  }
}

.single-banner-item:hover .banner-image a img {
  transform: scale(1.1);
}

/*----------------------------------------*/
/*  06. Feature CSS
/*----------------------------------------*/
.features-section .row .col-lg-4:last-child .single-feature {
  border-right: 0;
}

/* Single Feature CSS */
.single-feature {
  display: flex;
  align-items: center;
  border-right: 1px solid #e5e5e5;
}

@media only screen and (max-width: 767px) {
  .single-feature {
    border-right: 0;
    justify-content: center;
    text-align: center;
  }
}

.single-feature .feature-image {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .single-feature .feature-content {
    text-align: left;
  }
}

.single-feature .feature-content h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 10px;
}

.single-feature .feature-content p {
  font-size: 14px;
  line-height: 24px;
  max-width: 230px;
}

/*----------------------------------------*/
/*  07. Product CSS
/*----------------------------------------*/
/*-- Product --*/
.single-grid-product {
  transition: 0.6s;
}

.single-grid-product .product-label span {
  position: absolute;
  z-index: 2;
  color: #e33;
  text-align: center;
  display: inline-block;
  top: 20px;
  right: 20px;
  font-size: 15px;
  line-height: 30px;
  font-weight: 600;
}

.single-grid-product .product-label span.new {
  color: #e33;
}

.single-grid-product .product-label span.sale {
  color: #151515;
  left: auto;
  right: 20px;
}

.single-grid-product .product-image {
  position: relative;
  margin-bottom: 20px;
}

.single-grid-product .product-image > a {
  display: block;
  width: 100%;
}

.single-grid-product .product-image > a img {
  width: 100%;
  transition: 0.6s;
}

.single-grid-product .product-image > a img:nth-child(1) {
  visibility: visible;
  opacity: 1;
}

.single-grid-product .product-image > a img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
}

.single-grid-product .product-image .product-action {
  position: absolute;
  top: 50%;
  left: 15px;
  z-index: 6;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single-grid-product .product-image .product-action > ul > li {
  list-style: none;
  display: block;
  margin-bottom: 5px;
  opacity: 0;
}

.single-grid-product .product-image .product-action > ul > li a {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  background: #ffffff;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  color: #151515;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.single-grid-product .product-image .product-action > ul > li a:hover {
  color: #ffffff;
  background: #151515;
}

.single-grid-product .product-content {
  text-align: center;
}

.single-grid-product .product-content .title {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 31px;
  font-weight: 500;
}

.single-grid-product .product-content .title a {
  display: block;
}

.single-grid-product .product-content .title a:hover {
  color: #151515;
}

.single-grid-product .product-content p .main-price {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #929292;
}

.single-grid-product .product-content p .main-price.discounted {
  text-decoration: line-through;
}

.single-grid-product .product-content p .discounted-price {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-right: 10px;
  color: #e33;
}

.single-grid-product:hover .product-image > a img:nth-child(2) {
  visibility: visible;
  opacity: 1;
}

.single-grid-product:hover .product-image .product-action ul li {
  opacity: 1;
}

.single-grid-product:hover .product-image .product-action ul li:nth-of-type(1) {
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running zoomIn;
  animation: 500ms ease-in-out 0s normal none 1 running zoomIn;
}

.single-grid-product:hover .product-image .product-action ul li:nth-of-type(2) {
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running zoomIn;
  animation: 700ms ease-in-out 0s normal none 1 running zoomIn;
}

.single-grid-product:hover .product-image .product-action ul li:nth-of-type(3) {
  -webkit-animation: 900ms ease-in-out 0s normal none 1 running zoomIn;
  animation: 900ms ease-in-out 0s normal none 1 running zoomIn;
}

.single-grid-product:hover .product-image .product-action ul li:nth-of-type(4) {
  -webkit-animation: 1100ms ease-in-out 0s normal none 1 running zoomIn;
  animation: 1100ms ease-in-out 0s normal none 1 running zoomIn;
}

.single-grid-product.list-mode {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 20px;
}

.single-grid-product.list-mode .list-mode-image {
  flex-basis: 100px;
}

.single-grid-product.list-mode .list-mode-content {
  flex-basis: calc(100% - 100px);
  padding-left: 20px;
}

/* List Product CSS */
.product-list-item .single-grid-product .product-image {
  margin-bottom: 0;
}

.product-content-shop-list {
  text-align: left;
}

.product-content-shop-list h3 {
  font-size: 18px;
  line-height: 31px;
  font-weight: 500;
  margin: 0px 0 10px 0;
}

@media only screen and (max-width: 575px) {
  .product-content-shop-list h3 {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .product-content-shop-list h3 {
    margin-top: 10px;
  }
}

.product-content-shop-list .product-category-rating {
  margin: 0 0 10px 0;
}

.product-content-shop-list .product-category-rating .review a {
  font-weight: 400;
  line-height: 12px;
  color: #929292;
  margin-left: 10px;
}

.product-content-shop-list .product-category-rating .review a:hover {
  color: #292929;
}

.product-content-shop-list .product-category-rating .rating {
  margin-top: -3px;
}

.product-content-shop-list .product-category-rating .rating i {
  font-size: 15px;
}

.product-content-shop-list .product-category-rating .rating i.active {
  color: #F5C60D;
}

.product-content-shop-list .product-price {
  margin: 0 0 20px 0;
}

@media only screen and (max-width: 767px) {
  .product-content-shop-list .product-price {
    margin: 0 0 15px 0;
  }
}

.product-content-shop-list .product-price .main-price {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #929292;
}

.product-content-shop-list .product-price .main-price.discounted {
  text-decoration: line-through;
}

.product-content-shop-list .product-price .discounted-price {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-right: 10px;
  color: #e33;
}

.product-content-shop-list .product-desc {
  border-top: 1px solid #ebebeb;
  padding-top: 20px;
}

@media only screen and (max-width: 767px) {
  .product-content-shop-list .product-desc {
    padding-top: 15px;
  }
}

/*-- Product Countdown CSS --*/
.product-details-content .product-countdown-two {
  margin-bottom: 25px;
}

.product-details-content .product-countdown-two .single-count {
  padding: 15px 25px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-countdown-two .single-count {
    padding: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-countdown-two .single-count {
    padding: 15px;
  }
}

.product-details-content .product-countdown-two .single-count .single-countdown-times {
  font-size: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-countdown-two .single-count .single-countdown-times {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-countdown-two .single-count .single-countdown-times {
    font-size: 14px;
  }
}

.product-details-content .product-countdown-two .single-count .single-countdown-content {
  font-size: 10px;
}

.product-countdown-two .single-count {
  background-color: #292929;
  color: #ffffff;
  display: inline-block;
  text-transform: uppercase;
  padding: 15px;
  font-weight: 400;
  border-right: 1px solid #ffffff;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .product-countdown-two .single-count {
    padding: 10px;
  }
}

.product-countdown-two .single-count .single-countdown-times {
  display: block;
  font-size: 14px;
}

.product-countdown-two .single-count .single-countdown-content {
  font-size: 10px;
}

/* Shop Toolbar CSS */
.shop-area {
  position: relative;
  z-index: 9;
  background: #ffffff;
  padding: 30px 0 0 0px;
}

@media only screen and (max-width: 767px) {
  .shop-area {
    padding: 15px 0 0 0px;
  }
}

.shop-topbar-wrapper {
  position: relative;
  padding: 10px 0;
  margin: 0 0 40px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

@media only screen and (max-width: 575px) {
  .shop-topbar-wrapper {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 575px) {
  .grid-list-option {
    flex: 0 0 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .grid-list-option ul {
    justify-content: center;
    margin-bottom: 10px;
  }
}

.grid-list-option ul li {
  margin-right: 12px;
}

.grid-list-option ul li:last-child {
  margin-right: 0;
}

.grid-list-option ul li a {
  font-size: 18px;
  display: block;
  text-align: center;
  color: #151515;
}

.grid-list-option ul li a.active, .grid-list-option ul li a:hover {
  color: #151515;
}

.grid-list-option p {
  margin-left: 50px;
}

@media only screen and (max-width: 767px) {
  .grid-list-option p {
    margin-left: 8px;
  }
}

@media only screen and (max-width: 575px) {
  .toolbar-short-area {
    flex: 0 0 100%;
    width: 100%;
  }
}

.toolbar-shorter {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .toolbar-shorter {
    justify-content: center;
    margin-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .toolbar-shorter {
    justify-content: flex-start;
  }
}

.toolbar-shorter:last-child {
  margin-right: 0;
}

.toolbar-shorter label {
  display: block;
  margin-bottom: 0;
  margin-right: 15px;
  font-size: 14px;
  color: #151515;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .toolbar-shorter label {
    margin-right: 10px;
    width: 65px;
  }
}

.nice-select.wide {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  .nice-select.wide {
    width: 165px;
  }
}

.nice-select.small {
  height: 42px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
}

/*-- Product Details --*/
.product-details-images .lg-image {
  position: relative;
}

.product-details-images .lg-image > a {
  width: 100%;
}

.product-details-images .lg-image img {
  width: 100%;
}

.product-details-thumbs, .product-details-thumbs-2 {
  margin-top: 15px;
  overflow: hidden;
}

.product-details-thumbs .slick-list, .product-details-thumbs-2 .slick-list {
  margin: 0 -5px;
}

.product-details-thumbs .slick-list .slick-slide, .product-details-thumbs-2 .slick-list .slick-slide {
  padding: 0 5px;
}

.product-details-thumbs .slick-list .slick-slide > img, .product-details-thumbs-2 .slick-list .slick-slide > img {
  border: 1px solid transparent;
}

.product-details-thumbs .slick-active.slick-current > img, .product-details-thumbs-2 .slick-active.slick-current > img {
  border-color: #151515;
}

.product-details-thumbs .sm-image {
  cursor: pointer;
}

.product-large-thumb {
  position: relative;
  overflow: hidden;
}

a.popup-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px !important;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #373737;
  color: #fff;
  border-radius: 4px;
  z-index: 9;
}

.product-large-thumb a.popup-img:hover {
  background-color: #151515;
  color: #fff;
}

.product-details-content {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .product-details-content {
    margin-top: 30px;
  }
}

.product-nav {
  position: absolute;
  top: 0;
  right: 0;
}

.product-nav > a {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #d1d1d1;
  line-height: 28px;
  text-align: center;
  border-radius: 3px;
  color: #d1d1d1;
  position: relative;
  margin-right: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.product-nav > a:hover {
  background: #151515;
  border-color: #151515;
  color: #fff;
}

.product-details-content.text-center h2 {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .product-details-content.text-center h2 {
    max-width: 245px;
    font-size: 18px;
    margin: auto;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .product-details-content.text-center h2 {
    max-width: 195px;
    font-size: 17px;
    margin: 0;
    margin-bottom: 10px;
    text-align: left;
  }
}

.product-details-content h2 {
  color: #151515;
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 10px;
  max-width: 400px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content h2 {
    max-width: 370px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content h2 {
    font-size: 17px;
    max-width: 240px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .product-details-content h2 {
    font-size: 18px;
    max-width: 300px;
  }
}

@media only screen and (max-width: 479px) {
  .product-details-content h2 {
    font-size: 18px;
    max-width: 175px;
  }
}

.single-product-reviews {
  font-size: 15px;
  margin-bottom: 20px;
}

.single-product-reviews i.active {
  color: #F5C60D;
}

.review-link {
  font-size: 15px;
  color: #151515;
  margin-left: 10px;
  line-height: 30px;
  display: inline-block;
}

.review-link:hover {
  color: #151515;
}

.single-product-price {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
}

.single-product-price .price.new-price {
  color: #151515;
  font-weight: 500;
}

.single-product-price .regular-price {
  text-decoration: line-through;
}

.product-description p {
  line-height: 25px;
  color: #151515;
  margin-bottom: 30px;
}

.single-product-quantity {
  margin-bottom: 35px;
}

.single-product-quantity .product-quantity {
  background: #fff;
  border: 1px solid #ededed;
  float: left;
  margin-right: 15px;
  padding: 0 10px;
  border-radius: 0;
}

.single-product-quantity .product-quantity input {
  background: none;
  border: none;
  color: #151515;
  font-size: 20px;
  font-weight: 400;
  height: 55px;
  line-height: 53px;
  padding: 0;
  width: 60px;
}

@media only screen and (max-width: 767px) {
  .single-product-quantity .product-quantity input {
    height: 45px;
    width: 40px;
  }
}

.single-product-quantity .btn {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .single-product-quantity .btn {
    padding: 8px 15px;
  }
}

.single-product-quantity .btn i {
  font-size: 20px;
  margin-right: 10px;
}

.wishlist-compare-btn {
  margin-bottom: 35px;
}

@media only screen and (max-width: 767px) {
  .wishlist-compare-btn {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .wishlist-compare-btn {
    text-align: left;
  }
}

@media only screen and (max-width: 479px) {
  .wishlist-compare-btn {
    text-align: center;
  }
}

.wishlist-compare-btn a {
  background: none;
  border: 1px solid #ededed;
  color: #151515;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  height: 45px;
  line-height: 43px;
  text-align: center;
  width: 170px;
  border-radius: 0;
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-compare-btn a {
    width: 155px;
  }
}

.wishlist-compare-btn a.wishlist-btn {
  margin-right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-compare-btn a.wishlist-btn {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-compare-btn a.wishlist-btn {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.wishlist-compare-btn a.add-compare {
  border-radius: 5px;
}

.wishlist-compare-btn a.wishlist-btn:before {
  content: '\f08a';
  display: inline-block;
  font-family: FontAwesome;
  font-size: 16px;
  margin-right: 5px;
  vertical-align: top;
}

.wishlist-compare-btn a:hover {
  background: #151515;
  border-color: #151515;
  color: #fff;
}

.wishlist-compare-btn a:hover:before {
  -webkit-animation: crFlipX 2s ease-in-out infinite both;
  animation: crFlipX 2s ease-in-out infinite both;
}

.product-meta {
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
  padding: 15px 0;
  width: 100%;
}

.product-meta .posted-in {
  color: #151515;
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
  text-transform: uppercase;
}

.product-meta .posted-in > a {
  color: #151515;
  font-weight: 400;
  text-transform: none;
}

.product-meta .posted-in > a:hover {
  color: #151515;
}

.single-product-sharing {
  margin-bottom: 0;
  padding: 15px 0;
  width: 100%;
}

.single-product-sharing > h3 {
  color: #151515;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-right: 15px;
  text-transform: uppercase;
}

.single-product-sharing ul {
  display: inline-block;
  margin-bottom: 10px;
}

.single-product-sharing ul > li {
  display: inline-block;
  list-style: none;
  margin-bottom: 0;
  margin-right: 15px;
  position: relative;
}

.single-product-sharing ul li a {
  color: #555555;
  display: block;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
}

.single-product-sharing ul li a:hover {
  color: #151515;
}

/*Single Product variable css*/
.product-variants {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-variants > .product-variants-item {
  margin: 20px 0;
  display: block;
  margin-top: 0;
}

.product-variants > .product-variants-item:last-child {
  margin-left: 30px;
}

.product-variants-item span.control-label {
  margin-bottom: 6px;
  margin-bottom: 5px;
  display: block;
  width: 100%;
  text-transform: capitalize;
}

.product-variants .product-variants-item select {
  border: 1px solid #ebebeb;
  height: 40px;
  width: 60px;
  background: #fff;
  border: 1px solid #ebebeb;
  height: 40px;
  width: 60px;
  background: #fff;
}

.procuct-color > li {
  display: inline-block;
  list-style: none;
  margin-left: 5px;
}

.procuct-color > li:nth-child(2) > a > span.color {
  background: #00EEB3;
}

.procuct-color > li > a > span.color {
  background: #ffa07a;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.procuct-color > li.active > a > span.color, .procuct-color > li:hover > a > span.color {
  outline: 1px solid #D6B98C;
  outline-offset: 1px;
}

/*single product group css*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .group-cart-table {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .group-cart-table {
    margin-bottom: 20px;
  }
}

.group-cart-table .table {
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .group-cart-table .table {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .group-cart-table .table {
    margin-bottom: 15px;
  }
}

.group-cart-table .table td {
  text-align: center;
  vertical-align: middle;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .group-cart-table .table td {
    min-width: 150px;
  }
}

@media only screen and (max-width: 575px) {
  .group-cart-table .table td {
    min-width: 180px;
  }
}

.group-cart-table .quantity-field label {
  margin-right: 5px;
}

.group-cart-table .quantity-field input {
  width: 50px;
  text-align: center;
  border: 1px solid #ddd;
  height: 45px;
}

/*Single Product Tab Style 2 CSS*/
.single-product-area .slider-thumbs-2 {
  padding: 40px 0px;
}

.product-details-images-2 {
  float: right;
  width: calc(100% - 110px);
  padding-left: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-images-2 {
    width: calc(100% - 80px);
  }
}

@media only screen and (max-width: 767px) {
  .product-details-images-2 {
    width: calc(100% - 80px);
  }
}

.product-details-thumbs-2 {
  float: left;
  width: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-thumbs-2 {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-thumbs-2 {
    width: 80px;
  }
}

/*Single Product Tab Style 3 CSS*/
.product-details-images-2.tabstyle-3 {
  float: left;
  width: calc(100% - 110px);
  padding-right: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-images-2.tabstyle-3 {
    width: calc(100% - 80px);
  }
}

@media only screen and (max-width: 767px) {
  .product-details-images-2.tabstyle-3 {
    width: calc(100% - 80px);
  }
}

.product-details-thumbs-2.tabstyle-3 {
  float: right;
  width: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-thumbs-2.tabstyle-3 {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-thumbs-2.tabstyle-3 {
    width: 80px;
  }
}

/*Single Product gallery Style CSS*/
.lg-image.col-50 {
  float: left;
  width: 50%;
  padding: 5px;
  position: relative;
}

.lg-image.col-50 a.popup-img {
  bottom: 5px;
  right: 5px;
}

/*Single Product sticky Style CSS*/
.lg-image.sidebar-sticky {
  position: relative;
  margin-bottom: 20px;
}

.lg-image.sidebar-sticky:last-child {
  margin-bottom: 0;
}

/*Single Product slider box Style CSS*/
.slider-box {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .slider-box {
    margin-bottom: 0;
  }
}

.slider-box .slick-list {
  margin: 0 -10px;
}

.slider-box .slick-list .slick-slide {
  padding: 0 10px;
}

.slider-box-content .product-description p {
  max-width: 80%;
  margin: auto;
  margin-bottom: 15px;
}

.slider-box-content .product-quantity {
  float: none;
  display: inline-block;
}

.slider-box-content .add-to-link {
  display: inline-block;
}

/*-- Product Details Tab List --*/
.dec-and-review-menu {
  justify-content: center;
  padding: 15px 0;
  background-color: #f3f1ec;
}

.dec-and-review-menu > li {
  list-style: none;
  position: relative;
  z-index: 9;
  display: inline-block;
  margin-right: 50px;
}

@media only screen and (max-width: 575px) {
  .dec-and-review-menu > li {
    margin-bottom: 10px;
    margin-right: 0;
    text-align: center;
    display: block;
    width: 100%;
  }
}

.dec-and-review-menu > li:last-child {
  margin-right: 0;
}

.dec-and-review-menu > li > a {
  display: block;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
}

.dec-and-review-menu > li > a.active, .dec-and-review-menu > li > a:hover {
  color: #151515;
}

/*-- Product Details Tab Content --*/
.product-review-tab {
  padding-top: 100px;
  padding-bottom: 100px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .product-review-tab {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-review-tab {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-review-tab {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .product-review-tab {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.product-review-content-tab {
  padding-top: 40px;
  font-size: 15px;
  line-height: 26px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .product-review-content-tab {
    padding-top: 20px;
  }
}

.single-product-description p {
  line-height: 22px;
  color: #151515;
}

.review-page-comment h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.review-page-comment > ul {
  margin: 0 0 25px;
}

.review-page-comment > ul > li {
  margin: 0 0 20px;
  position: relative;
  list-style: none;
}

.product-comment img {
  width: 60px;
  float: left;
}

.product-comment-content {
  border: 1px solid #ededed;
  border-radius: 3px;
  margin-left: 80px;
  position: relative;
  padding: 15px 15px 0;
}

.product-comment-content:before {
  background: #fff;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;
  content: '';
  display: block;
  height: 10px;
  left: -6px;
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
}

.meta > strong {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 31px;
}

.meta > span {
  font-size: 15px;
  font-weight: 400;
  line-height: 31px;
}

.description p {
  font-size: 15px;
  color: #151515;
  line-height: 28px;
}

.comment-notes {
  margin: 0;
}

.rating i.fa:not(:last-child) {
  color: #F9BA48;
}

.comment-form-comment {
  margin-bottom: 10px;
}

.comment-form-comment textarea {
  border: 1px solid #ededed;
  border-radius: 0;
  height: 125px;
  padding: 10px;
  width: 100%;
  background: #f5f5f5;
}

.review-comment-form-author, .review-comment-form-email {
  margin-bottom: 10px;
}

.review-comment-form-author input, .review-comment-form-email input {
  background: #f5f5f5;
  border: none;
  border-radius: 0;
  height: 38px;
  line-height: 38px;
  max-width: 100%;
  width: 300px;
  display: block;
  padding: 0 0 0 10px;
  vertical-align: middle;
}

.review-comment-form-author input:focus, .review-comment-form-email input:focus, .comment-form-comment textarea:focus {
  outline: auto;
  outline-color: #151515;
}

.form-button {
  border: none !important;
  height: 42px !important;
  line-height: 40px !important;
  background: #151515;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 38px;
  letter-spacing: .1em;
  line-height: 38px;
  overflow: hidden;
  padding: 0 30px;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
}

.form-button:hover {
  background: #222;
  color: #fff;
}

/*----------------------------------------*/
/*  08. Testimonial CSS
/*----------------------------------------*/
/*-- Testimonial --*/
.testimonial-image {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .testimonial-image {
    margin-bottom: 30px;
  }
}

.testimonial-image > * {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeOutUp;
}

@media only screen and (max-width: 767px) {
  .testimonial .testimonial-inner {
    padding: 0 10px;
  }
}

.testimonial .testimonial-inner .testimonial-description blockquote {
  position: relative;
  font-size: 15px;
  line-height: 24px;
  max-width: 505px;
}

.testimonial .testimonial-inner .testimonial-author {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial .testimonial-inner .testimonial-author {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial .testimonial-inner .testimonial-author {
    padding-bottom: 15px;
  }
}

.testimonial .testimonial-inner .testimonial-author .author-thumb {
  width: 80px;
  border: 3px solid #d2d2d2;
  border-radius: 50px;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .testimonial .testimonial-inner .testimonial-author .author-thumb {
    margin-right: 10px;
  }
}

.testimonial .testimonial-inner .testimonial-author .author-thumb img {
  width: 100%;
}

.testimonial .testimonial-inner .testimonial-author .author-info h4 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .testimonial .testimonial-inner .testimonial-author .author-info h4 {
    font-size: 16px;
  }
}

.testimonial .testimonial-inner .testimonial-author .author-info span {
  display: block;
  font-size: 15px;
  line-height: 40px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .testimonial .testimonial-inner .testimonial-author .author-info span {
    font-size: 13px;
    line-height: 26px;
  }
}

/*-- Slide Image In Animation --*/
.slick-active {
  /*-- Slide Image For Hero Testimonial --*/
}

.slick-active .testimonial-image > * {
  animation-name: fadeInUp;
}

.slick-active .testimonial-image > *:nth-child(1) {
  animation-delay: 0.5s;
}

.slick-active .testimonial-image > *:nth-child(2) {
  animation-delay: 1s;
}

.slick-active .testimonial-image > *:nth-child(3) {
  animation-delay: 1.5s;
}

.slick-active .testimonial-image > *:nth-child(4) {
  animation-delay: 2s;
}

.slick-active .testimonial-image > *:nth-child(5) {
  animation-delay: 2.5s;
}

.slick-active .testimonial-image > *:nth-child(6) {
  animation-delay: 3s;
}

/*---------------------------------------
    09. Cart CSS
-----------------------------------------*/
/*-- Cart Table --*/
.cart-table .table {
  border-bottom: 2px solid #151515;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .cart-table .table {
    border-top: 2px solid #151515;
  }
}

.cart-table .table thead {
  background-color: #151515;
}

@media only screen and (max-width: 767px) {
  .cart-table .table thead {
    display: none;
  }
}

.cart-table .table thead tr th {
  text-align: center;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 12px 20px;
}

.cart-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 20px;
  vertical-align: middle;
  border-bottom: 1px solid #dddddd;
}

@media only screen and (max-width: 767px) {
  .cart-table .table tbody tr td {
    display: block;
    width: 100%;
    max-width: none;
    padding: 15px;
    text-align: left;
  }
}

.cart-table th.pro-thumbnail, .cart-table td.pro-thumbnail {
  max-width: 120px;
  min-width: 100px;
  width: 120px;
}

@media only screen and (max-width: 767px) {
  .cart-table th.pro-thumbnail a, .cart-table td.pro-thumbnail a {
    width: 120px;
  }
}

.cart-table th.pro-title, .cart-table td.pro-title {
  min-width: 200px;
}

.cart-table td.pro-thumbnail a {
  display: block;
  border: 1px solid #eeeeee;
}

.cart-table td.pro-thumbnail a img {
  width: 100%;
  background-color: #f6f7f8;
}

.cart-table td.pro-title a {
  font-size: 16px;
  font-weight: 600;
  color: #151515;
}

.cart-table td.pro-title a:hover {
  color: #151515;
}

.cart-table td.pro-price span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #151515;
}

.cart-table td.pro-quantity .pro-qty {
  display: inline-flex;
  text-align: center;
  border: 1px solid #eeeeee;
}

.cart-table td.pro-quantity .pro-qty .qtybtn {
  height: 42px;
  padding: 0 10px;
  border: none;
  background-color: transparent;
}

.cart-table td.pro-quantity .pro-qty input {
  height: 42px;
  width: 50px;
  text-align: center;
  border-width: 0 1px;
  border-style: solid;
  border-color: #eeeeee;
  color: #151515;
}

.cart-table td.pro-subtotal span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #151515;
}

.cart-table td.pro-stock span.in-stock {
  font-size: 14px;
  text-transform: capitalize;
  color: #30b878;
}

.cart-table td.pro-stock span.out-stock {
  color: #EB3E32;
}

.cart-table td.pro-remove a {
  display: block;
  font-weight: 600;
  color: #151515;
}

.cart-table td.pro-remove a i {
  font-size: 15px;
}

.cart-table td.pro-remove a:hover {
  color: #ff0000;
}

@media only screen and (max-width: 767px) {
  .cart-table td.pro-remove a {
    width: 60px;
    text-align: center;
  }
}

/*-- Calculate Shipping --*/
.calculate-shipping {
  margin-bottom: 23px;
}

.calculate-shipping h4 {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px;
}

.calculate-shipping form .nice-select {
  width: 100%;
  border-radius: 0;
  height: 55px;
  border: 1px solid #999999;
  line-height: 40px;
  padding: 9px 20px;
  color: #151515;
  background-color: transparent;
}

.calculate-shipping form input {
  width: 100%;
  height: 55px;
  border: 1px solid #999999;
  line-height: 24px;
  padding: 9px 20px;
  color: #151515;
  color: #151515;
  background-color: transparent;
}

.calculate-shipping form input[type="submit"] {
  font-weight: 700;
  color: #151515;
  background-color: #151515;
  border-color: #151515;
  width: 140px;
}

.calculate-shipping form input[type="submit"]:hover {
  background-color: #151515;
  border-color: #151515;
  color: #151515;
}

/*-- Discount Coupon --*/
.discount-coupon h4 {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px;
}

.discount-coupon form input {
  width: 100%;
  height: 55px;
  border: 1px solid #999999;
  line-height: 24px;
  padding: 9px 20px;
  color: #151515;
  background-color: transparent;
}

.discount-coupon form input[type="submit"] {
  font-weight: 700;
  color: #151515;
  background-color: #151515;
  border-color: #151515;
  width: 140px;
}

.discount-coupon form input[type="submit"]:hover {
  background-color: #151515;
  border-color: #151515;
  color: #151515;
}

/*-- Cart Summary --*/
.cart-summary {
  float: right;
  max-width: 410px;
  width: 100%;
  margin-left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-summary {
    margin-left: 0;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .cart-summary {
    margin-left: 0;
    max-width: 100%;
  }
}

.cart-summary .cart-summary-wrap {
  background-color: #f5f5f5;
  padding: 45px 50px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .cart-summary .cart-summary-wrap {
    padding: 25px 30px;
  }
}

.cart-summary .cart-summary-wrap h4 {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px;
  color: #151515;
}

.cart-summary .cart-summary-wrap p {
  font-weight: 600;
  line-height: 23px;
  color: #151515;
}

.cart-summary .cart-summary-wrap p span {
  float: right;
}

.cart-summary .cart-summary-wrap h2 {
  border-top: 1px solid #151515;
  padding-top: 9px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
  color: #151515;
  margin: 0;
}

.cart-summary .cart-summary-wrap h2 span {
  float: right;
}

.cart-summary .cart-summary-button {
  overflow: hidden;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-summary .cart-summary-button {
    display: flex;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .cart-summary .cart-summary-button {
    display: flex;
    justify-content: flex-start;
  }
}

.cart-summary .cart-summary-button button {
  margin-top: 10px;
  width: 180px;
  border-radius: 0;
  height: 44px;
  line-height: 24px;
  padding: 9px 20px;
  margin-left: 20px;
  float: right;
}

.cart-summary .cart-summary-button button:last-child {
  margin-left: 0;
}

.cart-summary .cart-summary-button button.checkout-btn {
  color: #151515;
  background-color: #151515;
  border-color: #151515;
}

.cart-summary .cart-summary-button button:hover {
  background-color: #151515;
  border-color: #151515;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-summary .cart-summary-button button {
    margin-left: 0;
    margin-right: 20px;
  }
  .cart-summary .cart-summary-button button:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .cart-summary .cart-summary-button button {
    margin-left: 0;
    margin-right: 10px;
    font-size: 12px;
  }
  .cart-summary .cart-summary-button button:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .cart-summary .cart-summary-button button {
    width: 130px;
  }
}

/*---------------------------------------
    10. Checkout CSS
-----------------------------------------*/
/*-- Checkout Title --*/
.checkout-title {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
}

/*-- Checkout Form --*/
.checkout-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.checkout-form .nice-select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  height: 45px;
  color: #151515;
  margin-bottom: 15px;
}

.checkout-form input {
  width: 100%;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #151515;
  margin-bottom: 15px;
}

.checkout-form input[type="checkbox"] {
  width: auto;
}

.checkout-form .check-box {
  float: left;
  margin-right: 70px;
}

@media only screen and (max-width: 575px) {
  .checkout-form .check-box {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .checkout-form .check-box {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.checkout-form .check-box:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 575px) {
  .checkout-form .check-box:last-child {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 479px) {
  .checkout-form .check-box:last-child {
    margin-bottom: 0px;
  }
}

.checkout-form .check-box input[type="checkbox"] {
  display: none;
}

.checkout-form .check-box input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #151515;
  margin: 0;
}

.checkout-form .check-box input[type="checkbox"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #999999;
  content: "";
  transition: all 0.3s ease 0s;
}

.checkout-form .check-box input[type="checkbox"] + label::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "\f00c";
  font-family: Fontawesome;
  font-size: 12px;
  line-height: 20px;
  opacity: 0;
  color: #151515;
  width: 20px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.checkout-form .check-box input[type="checkbox"]:checked + label::before {
  border: 2px solid #151515;
}

.checkout-form .check-box input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

/*-- Shipping Form --*/
#shipping-form {
  display: none;
}

/*-- Checkout Cart Total --*/
.checkout-cart-total {
  background-color: #151515;
  padding: 45px;
}

@media only screen and (max-width: 575px) {
  .checkout-cart-total {
    padding: 30px;
  }
}

.checkout-cart-total h4 {
  flex-basis: 18px;
  line-height: 23px;
  font-weight: 700;
  color: #ffffff;
}

.checkout-cart-total h4:first-child {
  margin-top: 0;
  margin-bottom: 25px;
}

.checkout-cart-total h4:last-child {
  margin-top: 15px;
  margin-bottom: 0;
}

.checkout-cart-total h4 span {
  float: right;
  display: block;
}

.checkout-cart-total ul {
  border-bottom: 1px solid #ffffff;
}

.checkout-cart-total ul li {
  color: #ffffff;
  font-size: 14px;
  line-height: 23px;
  font-weight: 600;
  display: block;
  margin-bottom: 16px;
}

.checkout-cart-total ul li span {
  color: #ffffff;
  float: right;
}

.checkout-cart-total p {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #ffffff;
  padding: 10px 0;
  border-bottom: 1px solid #ffffff;
  margin: 0;
}

.checkout-cart-total p span {
  float: right;
}

/*-- Checkout Payment Method --*/
.checkout-payment-method {
  background-color: #151515;
  padding: 45px;
}

@media only screen and (max-width: 575px) {
  .checkout-payment-method {
    padding: 30px;
  }
}

/*-- Single Payment Method --*/
.single-method {
  margin-bottom: 20px;
}

.single-method:last-child {
  margin-bottom: 0;
}

.single-method input[type="radio"] {
  display: none;
}

.single-method input[type="radio"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}

.single-method input[type="radio"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #ffffff;
  content: "";
  transition: all 0.3s ease 0s;
}

.single-method input[type="radio"] + label::after {
  position: absolute;
  left: 5px;
  top: 5px;
  display: block;
  content: "";
  opacity: 0;
  background-color: #151515;
  width: 10px;
  text-align: center;
  height: 10px;
  transition: all 0.3s ease 0s;
}

.single-method input[type="radio"]:checked + label::before {
  border: 2px solid #ffffff;
}

.single-method input[type="radio"]:checked + label::after {
  opacity: 1;
}

.single-method input[type="checkbox"] {
  display: none;
}

.single-method input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}

.single-method input[type="checkbox"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  display: block;
  border: 2px solid #ffffff;
  content: "";
  transition: all 0.3s ease 0s;
}

.single-method input[type="checkbox"] + label::after {
  position: absolute;
  left: 4px;
  top: 4px;
  display: block;
  content: "";
  opacity: 0;
  background-color: #151515;
  width: 8px;
  text-align: center;
  height: 8px;
  transition: all 0.3s ease 0s;
}

.single-method input[type="checkbox"]:checked + label::before {
  border: 2px solid #ffffff;
}

.single-method input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

.single-method p {
  display: none;
  margin-top: 8px;
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
}

/*-- Place Order --*/
.place-order {
  margin-top: 40px;
  float: left;
}

/*---------------------------------------
    11. Compare CSS
-----------------------------------------*/
/*-- Compare Table --*/
.compare-table .table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.compare-table .table tbody tr td:last-child {
  border-right: 1px solid #eeeeee;
}

.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 18px;
  font-weight: 600;
  color: #151515;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  padding-top: 70px;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  font-size: 13px;
  line-height: 15px;
  color: #444444;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .category:hover {
  color: #151515;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  font-weight: 600;
  color: #444444;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #151515;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price, .compare-table .table tbody tr td.pro-color, .compare-table .table tbody tr td.pro-stock {
  font-size: 16px;
  font-weight: 600;
  color: #444444;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart {
  position: relative;
  background-color: #151515;
  color: #fff;
  border-radius: 50px;
  display: inline-block;
  width: 170px;
  padding: 10px 25px 10px 25px;
  transition: all 0.3s ease-in-out;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart:hover {
  background-color: #222;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 26px;
  overflow: hidden;
  display: block;
  text-align: center;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart.added {
  padding-left: 25px;
  padding-right: 56px;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart.added i {
  left: calc(100% - 41px);
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #151515;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #444444;
  font-size: 14px;
}

/*---------------------------------------
    12. My Account CSS
-----------------------------------------*/
.myaccount-tab-menu {
  flex-direction: column;
  background-color: #ffffff;
}

.myaccount-tab-menu a {
  border: 1px solid #eeeeee;
  border-bottom: none;
  color: #151515;
  font-weight: 500;
  font-size: 15px;
  display: block;
  padding: 15px 15px 13px;
  text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #eeeeee;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #151515;
  color: #ffffff;
}

.myaccount-tab-menu a i {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

/*-- My Account Content -*/
.myaccount-content {
  background-color: #ffffff;
  font-size: 15px;
  border: 1px solid #eeeeee;
  padding: 30px;
}

@media only screen and (max-width: 575px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content h3 {
  border-bottom: 1px dashed #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.myaccount-content .welcome a {
  color: #000000;
}

.myaccount-content .welcome a:hover {
  color: #151515;
}

.myaccount-content .welcome strong {
  font-weight: 600;
}

.myaccount-content a.edit-address-btn {
  border-color: #151515;
}

.myaccount-content a.edit-address-btn i {
  padding-right: 5px;
}

.myaccount-content a.edit-address-btn:hover {
  background-color: #151515;
  border-color: #151515;
}

.myaccount-content button.save-change-btn {
  background: none;
  border: none;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #151515;
  border-color: #151515;
  width: 140px;
  padding: 10px 0;
  border-radius: 3px;
}

.myaccount-content button.save-change-btn:hover {
  background-color: #151515;
}

/*-- My Account Table -*/
.myaccount-table {
  white-space: nowrap;
  font-size: 15px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 20px 10px;
  vertical-align: middle;
}

.myaccount-table table td a:hover,
.myaccount-table .table td a:hover {
  color: #ffffff;
}

.saved-message {
  border-top: 3px solid #151515;
  border-top: 3px solid #151515;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
  font-size: 13px;
  padding: 20px;
}

/*-- My Account Details Form -*/
.account-details-form h4 {
  margin: 0;
}

.account-details-form input {
  display: block;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 0;
  line-height: 24px;
  padding: 11px 25px;
  color: #656565;
}

/*---------------------------------------
    13. Login Register CSS
-----------------------------------------*/
.form-login-title > h2, .form-register-title > h2 {
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .form-login-title > h2, .form-register-title > h2 {
    font-size: 24px;
    line-height: 20px;
  }
}

.login-form, .register-form {
  padding: 20px;
  border: 1px solid #d3ced2;
  margin: 30px 0;
  text-align: left;
  border-radius: 5px;
}

.form-fild, .login-submit, .lost-password, .register-submit {
  margin-bottom: 10px;
}

.login-submit label {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .login-submit label {
    margin-left: 10px;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.form-fild p {
  margin: 0;
}

.form-fild p span.required {
  color: #f23838;
}

.form-fild input {
  width: 100%;
  padding: 0 15px;
  height: 45px;
  border: 1px solid #dddddd;
  color: #151515;
  border-radius: 5px;
}

.lost-password > a {
  color: #a43d21;
}

.lost-password > a:hover {
  color: #151515;
}

/*----------------------------------------*/
/*  14. Faq CSS
/*----------------------------------------*/
.faq-desc {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-desc {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-desc {
    margin-bottom: 30px;
  }
}

.faq-desc h3 {
  color: #444;
  font-size: 18px;
  font-weight: 700;
}

.faq-desc p {
  color: #151515;
  margin: 0;
}

.faq-accordion .card {
  border-radius: 0;
  margin-bottom: 10px;
}

.faq-accordion .card.actives {
  border: 1px solid #151515;
}

.faq-accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f5f5f5;
  border: 0;
}

.faq-accordion .card-header a {
  display: block;
  padding: 15px 10px 15px 25px;
  cursor: pointer;
}

.faq-accordion .card-header a.collapsed:before {
  content: "\f067";
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 15px;
}

.faq-accordion .card-header a:after {
  content: "\f067";
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 15px;
  content: "\f068 ";
}

@media only screen and (max-width: 767px) {
  .faq-accordion .card-header a {
    font-size: 14px;
    padding-right: 30px;
  }
}

.faq-accordion .card-header a:hover {
  color: #151515 !important;
}

/* Faq Style Two CSS */
.faq-accordion .card-style-two {
  border-radius: 0;
  margin-bottom: 10px;
  background-color: transparent;
  border: 0;
}

.faq-accordion .card-style-two.actives {
  border: 0;
}

.faq-accordion .card-style-two.actives .card-header {
  background-color: #151515;
  border-color: #151515;
}

.faq-accordion .card-style-two.actives .card-header a {
  color: #ffffff;
}

.faq-accordion .card-style-two .card-header {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 0;
  text-decoration: none;
  line-height: 1.2;
  position: relative;
  cursor: pointer;
}

.faq-accordion .card-style-two .card-header:hover {
  background-color: #151515;
  border-color: #151515;
}

.faq-accordion .card-style-two .card-header a {
  display: block;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 14px !important;
  line-height: 22px;
  font-weight: 500;
  color: #151515;
}

@media only screen and (max-width: 767px) {
  .faq-accordion .card-style-two .card-header a {
    font-size: 13px;
  }
}

.faq-accordion .card-style-two .card-header a.collapsed::before {
  font-size: 18px;
  content: "\f067";
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 15px;
}

.faq-accordion .card-style-two .card-header a.collapsed::after {
  display: none;
}

.faq-accordion .card-style-two .card-header a:after {
  font-size: 18px;
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 12px;
  content: "\f068";
}

@media only screen and (max-width: 767px) {
  .faq-accordion .card-style-two .card-header a {
    padding: 15px 15px;
  }
}

.faq-accordion .card-style-two .card-header a:hover {
  color: #ffffff;
}

.faq-accordion .card-style-two .card-header a:hover {
  color: #ffffff !important;
}

.faq-accordion .card-body {
  border: 1px solid #ebebeb;
}

/*---------------------------------------
    15. Blog CSS
-----------------------------------------*/
/*-- Blog --*/
.blog .blog-inner .blog-media {
  margin-bottom: 15px;
}

.blog .blog-inner .blog-media .image {
  display: block;
}

.blog .blog-inner .blog-media .image img {
  width: 100%;
}

.blog .blog-inner .blog-audio iframe {
  width: 100%;
  height: 242px;
  border: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-inner .blog-audio iframe {
    height: 190px;
  }
}

.blog .blog-inner .blog-video iframe {
  width: 100%;
  height: 242px;
  border: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-inner .blog-video iframe {
    height: 190px;
  }
}

.blog .blog-inner .blog-gallery a {
  display: block;
}

.blog .blog-inner .blog-gallery a img {
  width: 100%;
}

.blog .blog-inner .content .meta {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.blog .blog-inner .content .meta li {
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-inner .content .meta li {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-inner .content .meta li {
    font-size: 13px;
  }
}

.blog .blog-inner .content .meta li::after {
  content: "-";
  margin: 0 10px;
}

.blog .blog-inner .content .meta li:last-child::after {
  display: none;
}

.blog .blog-inner .content .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-inner .content .title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-inner .content .title {
    font-size: 16px;
  }
}

.blog .blog-inner .content .desc {
  margin-top: 20px;
}

.blog .blog-inner .content .read-more {
  font-size: 16px;
  line-height: 30px;
  text-decoration: underline;
}

.blog .blog-inner .content .read-more:hover {
  color: #151515;
}

/*-- Blog Details --*/
.blog-details .blog-inner .blog-media {
  margin-bottom: 30px;
}

.blog-details .blog-inner .blog-media .image {
  display: block;
}

.blog-details .blog-inner .blog-media .image img {
  width: 100%;
}

.blog-details .blog-inner .blog-audio iframe {
  width: 100%;
  border: 0;
}

.blog-details .blog-inner .blog-video iframe {
  width: 100%;
  border: 0;
  height: 565px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .blog-details .blog-inner .blog-video iframe {
    height: 465px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details .blog-inner .blog-video iframe {
    height: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details .blog-inner .blog-video iframe {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details .blog-inner .blog-video iframe {
    height: 300px;
  }
}

.blog-details .blog-inner .content .meta {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.blog-details .blog-inner .content .meta li {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .blog-details .blog-inner .content .meta li {
    font-size: 12px;
  }
}

.blog-details .blog-inner .content .meta li::after {
  content: "-";
  margin: 0 10px;
}

.blog-details .blog-inner .content .meta li:last-child::after {
  display: none;
}

.blog-details .blog-inner .content .title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .blog-details .blog-inner .content .title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-details .blog-inner .content .title {
    font-size: 20px;
  }
}

.blog-details .blog-inner .content .desc {
  margin-top: 20px;
}

.blog-details .blog-inner .content .blog-post-list li {
  display: block;
  list-style: none;
  line-height: 30px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 20px;
}

.blog-details .blog-inner .content .blog-post-list li::before {
  position: absolute;
  content: "\f101";
  font-family: FontAwesome;
  top: 0;
  left: 0;
  z-index: 1;
}

.blog-details .blog-inner .content .tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.blog-details .blog-inner .content .tags li {
  display: flex;
  flex-wrap: wrap;
  line-height: 24px;
  margin-right: 15px;
}

.blog-details .blog-inner .content .tags li::after {
  content: ",";
  margin-left: 3px;
}

.blog-details .blog-inner .content .tags li:first-child::after, .blog-details .blog-inner .content .tags li:last-child::after {
  display: none;
}

.blog-details .blog-inner .content .tags li i {
  font-size: 18px;
  line-height: 24px;
}

.blog-details .blog-inner .content .tags li a {
  display: block;
}

.comments-wrapper h3 {
  font-weight: 600;
}

.commnent-list-wrap .comment {
  list-style-type: none;
  margin-top: 48px;
  padding-top: 37px;
  padding-left: 6px;
  border-top: 1px solid #eee;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .commnent-list-wrap .comment {
    margin-top: 25px;
  }
}

.commnent-list-wrap .comment .thumb {
  float: left;
}

@media only screen and (max-width: 767px) {
  .commnent-list-wrap .comment .thumb {
    float: none;
  }
}

.commnent-list-wrap .comment .thumb img {
  border-radius: 100%;
  width: 100px;
  height: 100px;
}

.commnent-list-wrap .comment .content {
  position: relative;
  overflow: hidden;
  margin-left: 115px;
}

@media only screen and (max-width: 767px) {
  .commnent-list-wrap .comment .content {
    margin-left: 0;
    margin-top: 30px;
  }
}

.commnent-list-wrap .comment .content .info h6 {
  font-size: 15px;
  font-weight: 500;
}

.commnent-list-wrap .comment .content .reply-btn a {
  color: #ffffff;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  display: inline-block;
  font-size: 12px;
  border: 1px solid #151515;
  background: #151515;
  padding: 3px 10px;
  border-radius: 10px 0;
  transition: 0.3s;
}

.commnent-list-wrap .comment .content .reply-btn a:hover {
  color: #151515;
  border: 1px solid #151515;
  background: transparent;
}

.commnent-list-wrap .comment .content .comment-footer span {
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}

.commnent-list-wrap .comment.comment-reply {
  padding-left: 61px;
}

@media only screen and (max-width: 767px) {
  .commnent-list-wrap .comment.comment-reply {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .commnent-list-wrap .comment.comment-reply {
    padding-left: 0;
  }
}

/*---------------------------------------
    16. Comment CSS
-----------------------------------------*/
/*-- Comment Wrap --*/
.comment-wrapper h3 {
  margin-bottom: 30px;
  font-weight: 600;
}

/*-- Comment Form --*/
.comment-form input {
  width: 100%;
  height: 50px;
  border: 1px solid #eeeeee;
  padding: 5px 20px;
  color: #151515;
}

.comment-form textarea {
  width: 100%;
  height: 120px;
  border: 1px solid #eeeeee;
  padding: 10px 20px;
  color: #151515;
  resize: none;
}

.comment-form input[type="submit"], .comment-form button, .comment-form .submit {
  width: auto;
  height: 50px;
  border: none;
  padding: 5px 30px;
  background-color: #151515;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
}

.comment-form input[type="submit"]:hover, .comment-form button:hover, .comment-form .submit:hover {
  background-color: #151515;
}

/*---------------------------------------
    17. Sidebar CSS
-----------------------------------------*/
/*-- Sidebar --*/
.common-sidebar-widget {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 50px;
  padding-bottom: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .common-sidebar-widget {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .common-sidebar-widget {
    margin-bottom: 30px;
  }
}

.common-sidebar-widget.sidebar-two {
  padding: 0;
  background-color: transparent;
}

.common-sidebar-widget:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

/*-- Sidebar Title --*/
.sidebar-title {
  margin: 0;
  padding: 0 0 25px;
  display: block;
  font-size: 18px;
  color: #151515;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-title {
    font-size: 16px;
  }
}

/*-- Sidebar Search --*/
.sidebar-search form {
  display: flex;
  border: 1px solid #e5e6e7;
}

.sidebar-search form input {
  flex: 1 0 calc(100% - 40px);
  max-width: calc(100% - 40px);
  height: 40px;
  border: none;
  background-color: transparent;
  padding: 0 15px;
  color: #151515;
}

.sidebar-search form button {
  max-width: 40px;
  flex: 1 0 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding: 0;
}

.sidebar-search form button i {
  font-size: 20px;
  line-height: 40px;
}

.sidebar-search form button:hover {
  color: #151515;
}

/*-- Sidebar List --*/
.sidebar-list li {
  margin-bottom: 12px;
}

.sidebar-list li:last-child {
  margin-bottom: 0;
}

.sidebar-list li a {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  color: #151515;
  display: block;
}

.sidebar-list li a img {
  width: 25px;
  margin-right: 15px;
}

.sidebar-list li a i {
  margin-right: 10px;
  font-size: 10px;
}

.sidebar-list li a:hover {
  color: #151515;
}

/*-- Sidebar Price --*/
.sidebar-price {
  overflow: hidden;
}

.sidebar-price #price-range {
  position: relative;
  width: 100%;
  height: 5px;
  margin: 7px 0;
  border-radius: 50px;
  background-color: #ccc;
  border: 0;
}

.sidebar-price #price-range .ui-slider-range {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #151515;
}

.sidebar-price #price-range .ui-slider-handle {
  position: absolute;
  top: 50%;
  display: block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  -webkit-transition: none;
  transition: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  border: 0;
}

.sidebar-price .price-amount {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  padding: 0;
  color: #151515;
  border: none;
  background-color: transparent;
  display: block;
}

.sidebar-price button {
  height: 35px;
  line-height: 30px;
  padding: 0 20px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 15px;
}

/*-- Sidebar Compare --*/
.compare-products-list {
  overflow: hidden;
}

.compare-products-list ul {
  margin-bottom: 20px;
}

.compare-products-list ul li {
  display: block;
  padding: 10px 0px;
  border-bottom: 1px solid #dfdfdf;
  overflow: hidden;
}

.remove-compare {
  float: right;
  display: inline-block;
  padding-right: 10px;
  float: right;
  display: inline-block;
}

.clear-btn {
  float: left;
  height: 35px;
  line-height: 35px;
  display: inline-block;
}

.compare-btn {
  float: right;
  height: auto;
  line-height: 1;
  background: transparent;
  text-transform: capitalize;
  text-decoration: underline;
  color: #151515;
  font-size: 15px;
}

.compare-btn:hover {
  color: #151515;
  background: transparent;
  text-decoration: underline;
}

/*-- Sidebar Blog --*/
.sidebar-blog {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e6e7;
}

.sidebar-blog:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.sidebar-blog .image {
  max-width: 50px;
  flex: 1 0 50px;
  height: 50px;
}

.sidebar-blog .image img {
  width: 100%;
}

.sidebar-blog .content {
  flex: 1 0 calc(100% - 50px);
  padding-left: 15px;
}

.sidebar-blog .content h5 {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.sidebar-blog .content p {
  margin-bottom: 0;
}

.sidebar-blog .content span {
  font-size: 15px;
  display: block;
  line-height: 18px;
}

/*-- Sidebar Tags --*/
.sidebar-tag {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.sidebar-tag li {
  padding: 5px;
}

.sidebar-tag li a {
  display: block;
  border: 1px solid #e5e6e7;
  padding: 5px 10px;
  font-size: 15px;
  color: #151515;
}

.sidebar-tag li a:hover {
  background-color: #151515;
  border-color: #151515;
  color: #ffffff;
}

/*-- Sidebar Product --*/
.sidebar-product {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e6e7;
}

.sidebar-product:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.sidebar-product .image {
  max-width: 80px;
  flex: 1 0 80px;
}

.sidebar-product .image img {
  width: 100%;
  border: 1px solid #f1f2f3;
}

.sidebar-product .content {
  flex: 1 0 calc(100% - 80px);
  padding-left: 15px;
}

.sidebar-product .content .title {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.sidebar-product .content .price span.old {
  margin-left: 10px;
  color: #151515;
  text-decoration: line-through;
}

.sidebar-product .content .ratting {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.sidebar-product .content .ratting i {
  font-size: 12px;
  line-height: 18px;
  margin-right: 5px;
  color: #151515;
}

.sidebar-product .content .ratting i:last-child {
  margin-right: 0;
}

/*----------------------------------------*/
/*  18. Brand CSS
/*----------------------------------------*/
/*-- Brand Slider --*/
.brand-slider .slick-dots, .brand-slider-two .slick-dots, .brand-slider-three .slick-dots {
  display: flex;
  justify-content: center;
}

/*-- Brand --*/
.single-brand {
  background: #fff;
  padding: 20px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .single-brand {
    padding: 10px;
  }
}

.single-brand .brand-image {
  position: relative;
  overflow: hidden;
}

.single-brand .brand-image img {
  width: 100%;
  height: auto;
}

/*---------------------------------------
    19. Contact CSS
-----------------------------------------*/
/*-- Map --*/
.contact-map {
  height: 400px;
  width: 100%;
}

/*-- Contact Information --*/
.contact-information h3 {
  margin-bottom: 30px;
  font-weight: 600;
}

.contact-information ul li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.contact-information ul li:last-child {
  margin-bottom: 0;
}

.contact-information ul li .icon {
  width: 50px;
}

.contact-information ul li .icon i {
  font-size: 30px;
}

.contact-information ul li .text {
  max-width: calc(100% - 50px);
}

.contact-information ul li .text span, .contact-information ul li .text a {
  display: block;
  line-height: 24px;
  max-width: 230px;
}

/*-- Contact Form --*/
.bl {
  border-left: 1px solid #dddd;
}

.name-fild-padding {
  padding-right: 30px;
}

.contact-form-wrap h3 {
  font-size: 36px;
  margin-bottom: 30px;
  font-weight: 600;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap h3 {
    font-size: 28px;
  }
}

.contact-form-wrap h3::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 70px;
  height: 2px;
  background: #151515;
}

.contact-form-wrap .fild-name {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.contact-form-wrap input {
  width: 100%;
  height: 50px;
  border: 0;
  border-bottom: 2px solid #151515;
  padding: 5px 0px;
  color: #151515;
  border-radius: 0px;
  height: 45px;
}

.contact-form-wrap input:focus {
  border-color: #777777;
  color: #111111;
}

.contact-form-wrap textarea {
  width: 100%;
  height: 200px;
  border: 0;
  border-bottom: 2px solid #151515;
  padding: 10px 00px;
  color: #151515;
  font-size: 16px;
  font-weight: 500;
  resize: none;
  border-radius: 0px;
}

.contact-form-wrap textarea:focus {
  border-color: #777777;
  color: #111111;
}

.contact-form-wrap button {
  margin-top: -5px;
  display: block;
  border-radius: 0px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.contact-form-wrap .check-box {
  float: left;
  margin-right: 70px;
}

.contact-form-wrap .check-box:last-child {
  margin-right: 0;
}

.contact-form-wrap .check-box input[type="checkbox"] {
  display: none;
}

.contact-form-wrap .check-box input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #151515;
  margin: 0;
}

.contact-form-wrap .check-box input[type="checkbox"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #999999;
  content: "";
  transition: all 0.3s ease 0s;
}

.contact-form-wrap .check-box input[type="checkbox"] + label::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "\f00c";
  font-family: Fontawesome;
  font-size: 12px;
  line-height: 20px;
  opacity: 0;
  color: #151515;
  width: 20px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.contact-form-wrap .check-box input[type="checkbox"]:checked + label::before {
  border: 2px solid #151515;
}

.contact-form-wrap .check-box input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

.form-messege.success {
  color: #1dbc51;
  font-weight: 700;
}

.form-messege.error {
  color: #ff1313;
}

/*----------------------------------------*/
/*  20. Newsletter CSS
/*----------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .newsletter-content {
    text-align: center;
    margin-bottom: 20px;
  }
}

.newsletter-content h2 {
  font-size: 36px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-content h2 {
    font-size: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-content h2 {
    font-size: 24px;
  }
}

.newsletter-content p {
  max-width: 300px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .newsletter-content p {
    margin: auto;
  }
}

.newsletter-form {
  position: relative;
  border: 2px solid #151515;
  width: 620px;
  max-width: 100%;
  margin: 0 auto;
}

.newsletter-form input {
  width: 100%;
  border: none;
  padding: 10px 20px;
  padding-right: 200px;
  line-height: 30px;
  height: 55px;
  color: #151515;
  font-size: 15px;
  background-color: transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-form input {
    padding-right: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-form input {
    height: 40px;
    padding-right: 150px;
  }
}

@media only screen and (max-width: 479px) {
  .newsletter-form input {
    padding-right: 128px;
    font-size: 11px;
  }
}

.newsletter-form button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: none;
  border: none;
  width: 200px;
  border-left: 2px solid #151515;
  font-size: 13px;
  font-weight: 600;
  color: #151515;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-form button {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-form button {
    width: 150px;
  }
}

@media only screen and (max-width: 479px) {
  .newsletter-form button {
    width: 100px;
    font-size: 11px;
  }
}

.newsletter-form button:hover {
  color: #151515;
}

/*---------------------------------------
    21. Modal CSS
-----------------------------------------*/
.quick-view-modal-container {
  display: block !important;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.quick-view-modal-container.show {
  visibility: visible;
  opacity: 1;
  z-index: 99999;
}

.quick-view-modal-container .modal-dialog {
  max-width: 80%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quick-view-modal-container .modal-dialog {
    max-width: 95%;
  }
}

@media only screen and (max-width: 767px) {
  .quick-view-modal-container .modal-dialog {
    max-width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .quick-view-modal-container .modal-dialog {
    max-width: 100%;
  }
}

.quick-view-modal-container .modal-header {
  border: none;
  padding-bottom: 0;
}

.quick-view-modal-container .modal-header button {
  z-index: 999;
}

.quick-view-modal-container .modal-body {
  padding: 30px 50px 70px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quick-view-modal-container .modal-body {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quick-view-modal-container .modal-body {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .quick-view-modal-container .modal-body {
    padding: 15px 15px 15px 15px;
  }
}

@media only screen and (max-width: 479px) {
  .quick-view-modal-container .modal-body {
    padding: 15px 15px 15px 15px;
  }
}

.quick-view-modal-container .modal-content {
  border-radius: 0;
  max-height: 850px;
  overflow: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .quick-view-modal-container .modal-content {
    max-height: 500px;
    overflow: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quick-view-modal-container .modal-content {
    max-height: 500px;
    overflow: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quick-view-modal-container .modal-content {
    max-height: 500px;
    overflow: auto;
  }
}

@media only screen and (max-width: 767px) {
  .quick-view-modal-container .modal-content {
    max-height: 500px;
    overflow: auto;
  }
}

/*---------------------------------------
    22. 404 CSS
-----------------------------------------*/
.error-text > h1 {
  font-size: 130px;
  color: #151515;
  font-weight: 900;
  letter-spacing: 10px;
  line-height: 130px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-text > h1 {
    font-size: 100px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-text > h1 {
    font-size: 90px;
    line-height: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .error-text > h1 {
    font-size: 120px;
    line-height: 120px;
  }
}

@media only screen and (max-width: 575px) {
  .error-text > h1 {
    font-size: 120px;
    line-height: 120px;
  }
}

.error-text > h2 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #151515;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-text > h2 {
    font-size: 22px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .error-text > h2 {
    font-size: 28px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .error-text > h2 {
    font-size: 18px;
    line-height: 18px;
  }
}

.error-text > p {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  margin: 20px auto 30px;
  color: #151515;
  max-width: 700px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-text > p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .error-text > p {
    font-size: 18px;
    line-height: 24px;
    max-width: 450px;
  }
}

@media only screen and (max-width: 575px) {
  .error-text > p {
    font-size: 15px;
    line-height: 24px;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.error-button a {
  background: #151515 none repeat scroll 0 0;
  border-radius: 35px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 40px;
  margin-top: 10px;
  padding: 0 30px;
  text-transform: uppercase;
}

.error-button a:hover {
  background: #333;
}

/*----------------------------------------*/
/*  23. Footer CSS
/*----------------------------------------*/
/*-- Footer Widget --*/
.footer-widget .footer-logo {
  margin-bottom: 30px;
}

.footer-widget .footer-logo a {
  display: block;
}

.footer-widget .footer-logo a img {
  width: 107px;
}

.footer-widget .title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: capitalize;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .title {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .title {
    margin-bottom: 15px;
  }
}

.footer-widget h2 {
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget h2 {
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget h2 {
    font-size: 22px;
    line-height: 28px;
  }
}

.footer-widget p {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.footer-widget .contact-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.footer-widget .opeaning-title {
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 0;
}

.footer-widget ul.address li {
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
}

.footer-widget ul.address li:last-child {
  margin-bottom: 0;
}

.footer-widget ul.address li i {
  margin-right: 15px;
  line-height: 28px;
  font-size: 18px;
}

.footer-widget ul.address li span {
  overflow: hidden;
}

.footer-widget ul.address li a {
  display: block;
}

.footer-widget ul.address li a:hover {
  color: #151515;
}

.footer-widget > .ft-menu {
  display: inline-block;
}

.footer-widget > .ft-menu > li {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}

.footer-widget > .ft-menu > li:last-child {
  margin-bottom: 0;
}

.footer-widget > .ft-menu > li > a {
  text-transform: capitalize;
  text-decoration: none;
  display: inherit;
  transition: all 0.4s ease-out;
}

.footer-widget > .ft-menu > li > a:hover {
  color: #151515;
}

/*-- Footer Bottom --*/
.footer-bottom {
  border-top: 1px solid #dddddd;
}

/*-- Copyright --*/
@media only screen and (max-width: 767px) {
  .copyright {
    text-align: center !important;
  }
}

.copyright p {
  line-height: 30px;
  font-size: 13px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .copyright p {
    margin-bottom: 15px;
  }
}

.copyright p a {
  color: #151515;
}

.copyright p a:hover {
  color: #151515;
}
