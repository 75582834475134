/*----------------------------------------*/
/*  14. Faq CSS
/*----------------------------------------*/
.faq-desc {
  margin-bottom: 50px;
  // Responsive
  @media #{$tablet-device}{
      margin-bottom: 40px;
  }
  @media #{$large-mobile}{
      margin-bottom: 30px;
  }
  h3 {
    color: #444;
    font-size: 18px;
    font-weight: 700;
  }
  p {
    color: $body-color;
    margin: 0;
  }
}

.faq-accordion {
  .card {
    &.actives {
      border: 1px solid $theme-color;
    }
    border-radius: 0;
    margin-bottom: 10px;
  }
  .card-header {
    padding: 0;
    margin-bottom: 0;
    background-color: #f5f5f5;
    border: 0;
    a {
      &.collapsed:before {
        content: "\f067";
        font-family: fontawesome;
        position: absolute;
        right: 15px;
        top: 15px;
      }
      &:after {
        content: "\f067";
        font-family: fontawesome;
        position: absolute;
        right: 15px;
        top: 15px;
        content: "\f068 ";
      }
      display: block;
      padding: 15px 10px 15px 25px;
      cursor: pointer;
      // Responsive
      @media #{$large-mobile}{
          font-size: 14px;
          padding-right: 30px;
      }
      &:hover {
        color: $theme-color !important;
      }
    }
  }
}

/* Faq Style Two CSS */
.faq-accordion {
.card-style-two {
  &.actives {
      & .card-header{
        background-color: $theme-color;
        border-color: $theme-color;
          & a{
              color: $white;
          }
      }
      border: 0;
  }
  border-radius: 0;
  margin-bottom: 10px;
  background-color: transparent;
  border: 0;
  .card-header {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: block;
    background-color: $white;
    border: 1px solid #eeeeee;
    padding: 0;
    text-decoration: none;
    line-height: 1.2;
    position: relative;
    cursor: pointer;
      &:hover{
          background-color: $theme-color;
          border-color: $theme-color;
      }
    a {
        //Responsive
        @media #{$large-mobile}{
            font-size: 13px;
        }
        &.collapsed{
            &::before {
            font-size: 18px;
            content: "\f067";
            font-family: fontawesome;
            position: absolute;
            right: 15px;
            top: 15px;
          }
            &::after{
                display: none;
            }
            
        }
      &:after {
        font-size: 18px;
        font-family: fontawesome;
        position: absolute;
        right: 15px;
        top: 12px;
        content: "\f068";
      }
      display: block;
      padding: 15px 20px;
      cursor: pointer;
      font-size: 14px !important;
      line-height: 22px;
      font-weight: 500;
      color: $heading-color;
      // Responsive
      @media #{$large-mobile}{
        padding: 15px 15px;
      }
      &:hover {
        color: $white;
      }
      &:hover{
        color: $white !important;
      }
    }
  }
}
.card-body{
  border: 1px solid #ebebeb;
}
}